import {API_BASE_URL} from '../../config';
import useFetch from '../useFetch';
import {ApiHook} from './types';

type Params = Record<string, never>;

export type Metaverse = {
  id: number,
  name: string,
  official: boolean
};

export type Metaverses = Array<Metaverse>;

const useMetaverses: ApiHook<Params, Metaverses> = () => {
  const URL = `${API_BASE_URL}/metaverses/`;
  const {data} = useFetch<Metaverses>(URL);
  return data;
};

export default useMetaverses;
