import React, {useEffect, useState} from 'react';

import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import WmsLayerDocumentation from './WmsLayerDocumentation';
import {getWmsLayers} from '../../utils/wmsLayers';


const WmsServiceDocumentation = () => {
  const {t} = useTranslation();
  const [layers, setLayers] = useState([]);

  useEffect(() => {
    getWmsLayers().then(setLayers);
  }, []);

  return <Box sx={{height: '100%', maxWidth: '1200px', p: 2}}>
    <Grid item sx={{mt: 1, mb: 3}}>
      <Typography
        variant='h5'
        sx={{fontWeight: 'bold', whiteSpace: 'nowrap', mr: 3}}
      >
        {t('geoservices.wmsDocumentationTitle')}
      </Typography>
      <Divider/>
    </Grid>
    <>
      {layers.map(layer => <WmsLayerDocumentation key={layer.Name} {...layer} />)}
    </>
  </Box>;
};

export default WmsServiceDocumentation;
