import React, {useState} from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';

import {GEOSERVER_BASE_URL, GEOSERVER_LANDPARCEL_LAYER} from '../../config';
import useFetch from '../../hooks/useFetch';

const WmsLayerPreview = ({baseUrl, layers, styles, format, srs, time, width, height, bbox}) => {

  const getMapParams = new URLSearchParams({
    service: 'WMS',
    version: '1.1.0',
    request: 'GetMap',
    exceptions: 'application/vnd.ogc.se_inimage',
    transparent: true,
    layers,
    styles,
    format,
    srs,
    time,
    width,
    height,
    bbox
  });

  const getMapUrl = `${baseUrl}/wms?${getMapParams.toString()}`;

  const [clickedPixel, setClickedPixel] = useState({});
  const handleImgClick = (e) => {
    setClickedPixel({
      i: e.nativeEvent.offsetX,
      j: e.nativeEvent.offsetY
    });
  };

  const getFeatureInfoParams = new URLSearchParams({
    service: 'WMS',
    version: '1.3.0',
    request: 'GetFeatureInfo',
    exceptions: 'application/json',
    layers,
    styles,
    srs,
    time,
    width,
    height,
    bbox,
    query_layers: layers,
    info_format: 'application/json',
    ...clickedPixel
  });

  const {data: getFeatureInfoResponse} = useFetch(clickedPixel.i && clickedPixel.j && `${baseUrl}/wms?${getFeatureInfoParams.toString()}`);

  const featureInfoProperties = getFeatureInfoResponse?.features[0]?.properties; // Pick the first feature only

  return <>
    <Box sx={{width, height}}>
      <img style={{position: 'relative', cursor: 'pointer'}} src={getMapUrl} alt={getMapUrl} onClick={handleImgClick}/>
      {
        featureInfoProperties && <Tooltip
          title={
            Object.entries(featureInfoProperties)
              .map(([key, value]) => <div key={key}>
                <span><b>{key}</b></span>: <span>{value}</span>
              </div>)}
        >
          <div style={{position: 'absolute', left: clickedPixel.i, top: clickedPixel.j, padding: 1}}/>
        </Tooltip>
      }
    </Box>

    <Box sx={{maxWidth: width, overflow: 'auto', p: 1, mb: 2}}>
      <Link target='_blank' href={getMapUrl} variant='caption' sx={{whiteSpace: 'nowrap'}}>{getMapUrl}</Link>
    </Box>
  </>;
};

WmsLayerPreview.propTypes = {
  baseUrl: PropTypes.string,
  layers: PropTypes.string,
  styles: PropTypes.string,
  format: PropTypes.string,
  srs: PropTypes.string,
  time: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  bbox: PropTypes.arrayOf(PropTypes.number)
};

WmsLayerPreview.defaultProps = {
  baseUrl: GEOSERVER_BASE_URL,
  layers: GEOSERVER_LANDPARCEL_LAYER,
  styles: '',
  format: 'image/png8',
  srs: 'EPSG:25831',
  time: '',
  width: 1000,
  height: 601,
  bbox: [497181.62564159045, 4645795.518290271, 516269.4908714284, 4657248.237428174]
};

export default WmsLayerPreview;
