import React, {FC} from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import {useTranslation} from 'react-i18next';

interface SubsectionTitleProps {
  titleKey: string,
  uppercase?: boolean
}

const SubsectionTitle: FC<SubsectionTitleProps> = ({titleKey, uppercase = false}) => {
  const {t} = useTranslation();
  return (
    <Box mt={2} mb={1}>
      <Typography
        variant='subtitle1'
        sx={{fontWeight: 'bold', textTransform: uppercase ? 'uppercase' : 'capitalize' }}
      >
        {t(titleKey)}
      </Typography>
      <Divider/>
    </Box>
  );
};

export default SubsectionTitle;
