import {API_BASE_URL} from '../../config';
import {ApiHook} from './types';
import {useEffect, useState} from 'react';
import {Variable} from '../../types/commonTypes';
import {getLayerDefaultDate} from '../../utils/wmsLayers';

type Params = {
  metaverseId: number,
  variableConfig: Variable
}

export type ApiResponse = {
  date: string
};

const useCurrentDate: ApiHook<Params, string> = ({
  metaverseId, variableConfig
}) => {
  const [currentDate, setCurrentDate] = useState<string>();

  useEffect(() => {
    if(metaverseId === undefined || variableConfig === undefined) {
      setCurrentDate(undefined);
    } else if (variableConfig.isRaster) {
      getLayerDefaultDate(variableConfig.apiFieldName).then(setCurrentDate);
    } else {
      const URL = `${API_BASE_URL}/metaverses/${metaverseId}/landparcels/state/date/`;
      fetch(URL)
        .then(response => response.json())
        .then((data: ApiResponse) => setCurrentDate(data?.date));
    }
  }, [metaverseId, variableConfig]);

  return currentDate;
};

export default useCurrentDate;
