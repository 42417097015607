import GeoJSON from 'geojson';
import {TileParams} from '../../types/commonTypes';
import {GEOSERVER_WFS_URL} from '../../config';

const loadWfs = async (params: TileParams): Promise<Array<GeoJSON.Feature>> => {
  const {mapEntity} = params;
  const response = await fetch(`${GEOSERVER_WFS_URL(mapEntity)}&srsName=EPSG:4326`);
  return (await response.json()).features;
};

export default loadWfs;
