import React from 'react';

import LanguageIcon from '@mui/icons-material/Language';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {useTranslation} from 'react-i18next';
import {useMatch, useNavigate} from 'react-router-dom';

//STYLES
const inputSelectStyles = {
  '&.MuiInput-root:before': {
    borderBottom: '0px solid white'
  },
  '&.MuiInput-root:hover:before': {
    borderBottom: '0px solid white'
  },
  '&.MuiInput-root:hover:after': {
    borderBottom: '0px solid white'
  },
  color: 'white',
  '& .MuiSelect-icon': {
    color: 'white'
  },
  '& .MuiInput-underline:after': {
    border: '2px solid yellow'
  },
};

const LanguagePicker = () => {
  const {i18n} = useTranslation();
  const relPath = useMatch(':lang/*')?.params['*'];
  const navigate = useNavigate();

  const handleLangChange = (event) => {
    const targetLang = event.target.value;
    navigate(`/${targetLang}/${relPath}`);
  };

  return <>
    <LanguageIcon sx={{color: 'white'}}/>
    <FormControl variant="standard" sx={{m: 1, minWidth: 75}}>
      <Select
        value={i18n.resolvedLanguage}
        onChange={handleLangChange}
        sx={inputSelectStyles}
      >
        <MenuItem value={'ca'}>Català</MenuItem>
        <MenuItem value={'en'}>English</MenuItem>
        <MenuItem value={'es'}>Español</MenuItem>
      </Select>
    </FormControl>
  </>;
};

export default LanguagePicker;
