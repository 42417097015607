import React, {FC} from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useTranslation} from 'react-i18next';
import Grid from '@mui/material/Grid';
import {LandparcelVersion} from '../hooks/api/useLandparcelVersion';

export interface LandparcelVersionProps {
  landparcelVersion?: LandparcelVersion
}
export const LandparcelVersionGrid: FC<LandparcelVersionProps> = ({landparcelVersion}) => {
  const {t} = useTranslation();

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      ml={4} mt={3}>
      <Grid item sx={{maxWidth: '500px'}}>
        <Box sx={{height: '131px', marginTop: 4}}>
          <Typography color="text.secondary" sx={{mb: 1.5}}>
            <b>{t('landparcelVersion.titles.crop1_id')}:</b> {landparcelVersion?.crop1 || '--'}
          </Typography>
          <Typography color="text.secondary" sx={{mb: 1.5}}>
            <b>{t('landparcelVersion.titles.crop2_id')}:</b> {landparcelVersion?.crop2 || '--'}
          </Typography>
          <Typography sx={{mb: 1.5}} color="text.secondary">
            <b>{t('landparcelVersion.titles.soil_class_id')}:</b> {landparcelVersion?.soilclass || '--'}
          </Typography>
          <Typography sx={{mb: 1.5}} color="text.secondary">
            <b>{t('landparcelVersion.titles.irrigation_method_id')}:</b> {landparcelVersion?.irrigationmethod || '--'}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default LandparcelVersionGrid;
