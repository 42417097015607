import React, {FC, useState} from 'react';
import {Dialog, DialogActions, DialogContent} from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {useTranslation} from 'react-i18next';
import Box from '@mui/material/Box';

const paperProps = {sx: {height: 'auto', pb: 1}};

const dialogContentSx = {mt: 2, textAlign: 'center'};

const actionsSx = {m: 2, margin: 'auto'};

const Disclaimer: FC = () => {
  const {t} = useTranslation();
  const [isOpen, setOpen] = useState<boolean>(true);
  const isDisclaimerAccepted = !!localStorage.getItem('irriter.isDisclaimerAccepted');

  const handleAccept = () => {
    localStorage.setItem('irriter.isDisclaimerAccepted', 'yes');
    setOpen(false);
  };

  return !isDisclaimerAccepted ?
    <Dialog open={isOpen} fullWidth PaperProps={paperProps}>
      <DialogContent sx={dialogContentSx}>
        <Box sx={{mb: 2}}>
          <img src="images/Logo-IRTA-simple.png" alt="Logo IRTA" height={60}/>
        </Box>
        <Typography gutterBottom sx={{fontWeight: 'bold', letterSpacing: 1.35}}>
          {t('disclaimer.title').toUpperCase()}
        </Typography>
        <Typography>
          {t('disclaimer.content')}
        </Typography>
      </DialogContent>
      <DialogActions sx={actionsSx}>
        <Button variant='contained' onClick={handleAccept}>{t('disclaimer.accept')}</Button>
      </DialogActions>
    </Dialog> :
    null;
};

export default Disclaimer;
