import React from 'react';

import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

import Logo_geomatico from '../img/girona/Logo_geomatico.png';
import Logo_CDTI from '../img/bardenas/cdti.jpeg';
import Logo_IRTA from '../img/bardenas/Logo_IRTA.png';

const SponsorsBardenas = () => {
  return <Box sx={{display: 'flex', alignItems: 'flex-end', flexGrow: 2, padding: '12px'}}>
    <Box sx={{display: 'flex', justifyContent: 'space-around', alignItems: 'center', flexWrap: 'wrap'}}>
      <Link href='https://www.cdti.es/'
        target='_blank'>
        <img src={Logo_CDTI} width='100%' alt="Girona, regió sensible a l'aigua"/>
      </Link>
      <img src={Logo_IRTA} width='20%' alt="Girona, regió sensible a l'aigua" style={{margin: '8px'}}/>
      <div style={{marginTop: 5}}>
        <Link href='https://geomatico.es' target='_blank'>
          <img src={Logo_geomatico} width={80} alt='geomatico.es'/>
        </Link>
      </div>
    </Box>
  </Box>;
};

export default SponsorsBardenas;