import {API_BASE_URL} from '../../config';
import useFetch from '../useFetch';
import {ApiHook} from './types';

type Params = {
  metaverseId: number,
  landparcelId: number,
  year: number,
  lang: string
}

export type LandparcelVersion = {
  crop1: string,
  crop2: string,
  irrigationmethod: string,
  soilclass: string
};

const useLandparcelVersion: ApiHook<Params, LandparcelVersion> = ({
  metaverseId,
  landparcelId,
  year,
  lang
}) => {
  const URL = metaverseId !== undefined && landparcelId !== undefined && year !== undefined && lang !== undefined?
    `${API_BASE_URL}/metaverses/${metaverseId}/landparcels/${landparcelId}/version/?year=${year}&lang=${lang}` :
    undefined;
  const {data} = useFetch<LandparcelVersion>(URL);
  return data;
};

export default useLandparcelVersion;
