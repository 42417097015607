import useFetch from './useFetch';
import {LatLonZoomType} from './usePosition';

export type Profile = {
  profile: string,
  title: Record<string, string>,
  initialMapPosition: LatLonZoomType,
  dataExtent: [number, number, number, number],
  primaryColor: string,
  secondaryColor: string
};

const useProfile = (): Profile | undefined => {
  const {data: profile} = useFetch<Profile>('profile/profile.json');
  return profile;
};

export default useProfile;
