import {
  INITIAL_SELECTED_VARIABLE,
  LANDPARCEL_MIN_ZOOM,
  LANDPARCEL_VARIABLES, RASTER_VARIABLES,
  SECTOR_MIN_ZOOM,
  SECTOR_VARIABLES
} from '../config';

import useMetaverses from './api/useMetaverses';
import useYears from './api/useYears';
import {position2viewport} from './usePosition';
import {MapEntity} from '../types/commonTypes';
import useCurrentDate from './api/useCurrentDate';
import useDates from './api/useDates';

const computeDate = (prevDate: string | undefined, dates: Array<string> | undefined, currentDate: string | undefined, isCurrentYear: boolean) => {
  if (!dates?.length) {
    return currentDate; // No timeseries, return current date
  } else {
    if (prevDate) {
      if(dates.includes(prevDate)) {
        return prevDate;
      } else {
        // Finds the closest date to prevDate into dates
        const goal = new Date(prevDate).getTime();
        const closest = dates
          .map(d => new Date(d).getTime())
          .reduce((prev, curr) =>
            (Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev)
          );
        return new Date(closest).toISOString().split('T')[0];
      }
    } else {
      if (isCurrentYear) {
        return currentDate; // Not in possible values, current year => set to current
      } else {
        return dates[dates.length - 1]; // Not in possible values, other year => set to latest
      }
    }
  }
};

const computeMapEntity = (position: string | undefined): MapEntity => {
  if (position === undefined) {
    return MapEntity.DISTRICT;
  } else {
    const zoom = position2viewport(position).zoom;
    if (zoom >= LANDPARCEL_MIN_ZOOM) {
      return MapEntity.LANDPARCEL;
    } else if (zoom >= SECTOR_MIN_ZOOM) {
      return MapEntity.SECTOR;
    } else {
      return MapEntity.DISTRICT;
    }
  }
};

interface Inputs {
  position?: string,
  metaverseId?: number,
  variable?: string,
  year?: number,
  date?: string
}

const useMapData = (inputs: Inputs = {}) => {
  const metaverses = useMetaverses({});

  const mapEntity = computeMapEntity(inputs.position);
  const variables = {...(mapEntity === MapEntity.LANDPARCEL ? LANDPARCEL_VARIABLES : SECTOR_VARIABLES), ...RASTER_VARIABLES}; // SECTOR_VARIABLES also valid for DISTRICTS

  const variable = inputs.variable && variables[inputs.variable] ? inputs.variable : INITIAL_SELECTED_VARIABLE; // If not available, set to a default one
  // @ts-ignore Acceso a key de objeto con string
  const variableConfig = variables[variable];

  // The already selected metaverse id if valid, or the official one, or undefined
  const metaverse = metaverses?.find((metaverse) => metaverse.id === inputs.metaverseId)?.id || metaverses?.find((metaverse) => metaverse.official)?.id;

  const yearsResponse = useYears({metaverseId: inputs.metaverseId});
  const years = yearsResponse ? yearsResponse.available_years : undefined;
  const currentYear = yearsResponse?.current_year;

  // The already selected year if valid, or the current one, or undefined
  const year = years?.find((year: number) => year === inputs.year) || currentYear;
  const isCurrentYear = currentYear !== undefined && year !== undefined && currentYear === year;

  const currentDate = useCurrentDate({metaverseId: inputs.metaverseId, variableConfig: variableConfig});
  const dates = useDates({metaverseId: inputs.metaverseId, mapEntity: mapEntity, variableConfig: variableConfig, year: year});

  // The already selected date if valid, or first available date for inputs.metaverse on current year, or today for current year, or undefined
  const date = computeDate(inputs.date, dates, currentDate, isCurrentYear);

  return {
    metaverses,
    metaverseId: metaverse,
    mapEntity,
    variable: variable,
    years,
    currentYear,
    year,
    dates,
    currentDate,
    date
  };
};

export default useMapData;
