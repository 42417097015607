import React, {FC, useCallback, useEffect, useMemo} from 'react';
import bbox from '@turf/bbox';
import Map from '@geomatico/geocomponents/Map/Map';
import usePosition from '../hooks/usePosition';
import {Sources, AnyLayer} from 'mapbox-gl';
import useProfile from '../hooks/useProfile';
import GeoJSON from 'geojson';

export interface MiniMapProps {
  feature: GeoJSON.Feature
}

const MiniMap: FC<MiniMapProps> = ({feature}) => {
  const profile = useProfile();
  const {viewport, setViewport} = usePosition();

  useEffect(() => {
    if(!viewport && profile?.initialMapPosition) {
      setViewport(profile.initialMapPosition);
    }
  }, [profile]);

  const mapRef = useCallback(map => {
    map && map.fitBounds(bbox(feature), {padding: 48});
  }, [feature]);

  const sources = useMemo<Sources | undefined>(() => feature ? {
    data: {
      type: 'geojson',
      data: feature
    }
  } : undefined
  , [feature]);

  const layers = useMemo<Array<AnyLayer>>(() => feature ? [{
    id: 'data-fill',
    source: 'data',
    type: 'fill',
    paint: {
      'fill-color': 'black',
      'fill-opacity': 0.33
    }
  }, {
    id: 'data-thick-outline',
    source: 'data',
    type: 'line',
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-width': 7,
      'line-color': 'black',
      'line-blur': 2
    }
  }, {
    id: 'data-thin-outline',
    source: 'data',
    type: 'line',
    layout: {
      'line-cap': 'round',
      'line-join': 'round'
    },
    paint: {
      'line-width': 3,
      'line-color': 'white'
    }
  }, {
    id: 'data-label',
    source: 'data',
    type: 'symbol',
    minzoom: 14,
    layout: {
      'text-font': ['Open Sans Semibold'],
      'text-field': ['get', 'lpis_code']
    },
    paint: {
      'text-color': 'white',
      'text-halo-color': 'black',
      'text-halo-width': 1
    }
  }] : []
  , [feature]);

  return <Map
    ref={mapRef}
    mapStyle='https://geoserveis.icgc.cat/contextmaps/hibrid.json'
    viewport={viewport}
    onViewportChange={setViewport}
    sources={sources}
    layers={layers}
    maxZoom={18}
    attributionControl={false}
  />;
};

export default MiniMap;
