import {FC} from 'react';
import {MapboxOverlay, MapboxOverlayProps} from '@deck.gl/mapbox/typed';
import {useControl} from 'react-map-gl';

export type DeclGLOverlayProps = MapboxOverlayProps & {
  interleaved?: boolean;
}

const DeckGLOverlay: FC<DeclGLOverlayProps> = (props) => {
  const overlay = useControl<MapboxOverlay>(() => new MapboxOverlay(props));
  overlay.setProps(props);
  return null;
};

export default DeckGLOverlay;
