import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const EvapotranspirationIcon = (props) => (
  <SvgIcon
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    xmlSpace="preserve"
    {...props}
  >
    <path d="M2.829 14.04c-.801-.668-1.435-1.195-1.435-2.272 0-1.076.634-1.604 1.435-2.271.697-.579 1.521-1.281 1.817-2.436h2.663L3.853 1.074.396 7.061h2.763c-.247.524-.687.92-1.222 1.364C1.075 9.144 0 10.038 0 11.768s1.074 2.625 1.937 3.343c.802.668 1.435 1.194 1.435 2.272 0 1.077-.633 1.604-1.435 2.271C1.075 20.375 0 21.268 0 23h1.394c0-1.078.634-1.604 1.435-2.273.863-.718 1.937-1.613 1.937-3.344.001-1.731-1.074-2.626-1.937-3.343zM11.175 14.04c-.801-.668-1.435-1.195-1.435-2.272 0-1.076.633-1.604 1.435-2.271.696-.579 1.521-1.281 1.816-2.436h2.663l-3.456-5.987-3.456 5.987h2.763c-.247.524-.687.92-1.222 1.364-.863.718-1.937 1.612-1.937 3.342s1.074 2.625 1.937 3.343c.802.668 1.435 1.194 1.435 2.272 0 1.077-.633 1.604-1.435 2.271-.863.722-1.936 1.615-1.936 3.347h1.394c0-1.078.633-1.604 1.435-2.273.863-.718 1.938-1.613 1.938-3.344 0-1.731-1.075-2.626-1.939-3.343zM19.522 14.04c-.803-.668-1.436-1.195-1.436-2.272 0-1.076.633-1.604 1.436-2.271.695-.579 1.52-1.281 1.814-2.436H24l-3.455-5.987-3.459 5.987h2.766c-.25.524-.688.92-1.223 1.364-.863.718-1.939 1.612-1.939 3.342s1.074 2.625 1.938 3.343c.803.668 1.436 1.194 1.436 2.272 0 1.077-.633 1.604-1.434 2.271-.865.72-1.939 1.613-1.939 3.346h1.396c0-1.078.633-1.604 1.436-2.273.861-.718 1.936-1.613 1.936-3.344-.001-1.73-1.076-2.625-1.937-3.342z" />
  </SvgIcon>
);

export default EvapotranspirationIcon;