import {API_BASE_URL} from '../../config';
import useFetch from '../useFetch';
import {ApiHook, LandparcelTimeseriesVariable, ChartTimeseries} from './types';

type Params = {
  metaverseId: number,
  landparcelId: number,
  year: number
}

export type LandparcelTimeseries = Record<LandparcelTimeseriesVariable, ChartTimeseries>;

const useLandparcelTimeseries: ApiHook<Params, LandparcelTimeseries> = ({
  metaverseId,
  landparcelId,
  year
}) => {
  const URL = metaverseId !== undefined && landparcelId !== undefined && year !== undefined ?
    `${API_BASE_URL}/metaverses/${metaverseId}/landparcels/${landparcelId}/timeseries/?year=${year}` :
    undefined;
  const {data} = useFetch<LandparcelTimeseries>(URL);
  return data;
};

export default useLandparcelTimeseries;
