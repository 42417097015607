import {GEOSERVER_BASE_URL} from '../config';
import xml from '../xml/xml';

export type WMSLayer = {
  Name: string,
  Dimension?: WMSDimension
}

type WMSCapabilities = {
  WMS_Capabilities: {
    Capability: {
      Layer: {
        Layer: Array<WMSLayer>
      }
    }
  }
}

type WMSDimension = {
  name: string,
  '#text': string,
  default: string
}

let cached: Promise<Array<WMSLayer>> | undefined = undefined;

export const getWmsLayers = (): Promise<Array<WMSLayer>> => {
  if (cached === undefined) {
    const getCapabilitiesParams = new URLSearchParams({
      service: 'WMS',
      version: '1.3.0',
      request: 'GetCapabilities'
    });
    const URL = `${GEOSERVER_BASE_URL}/ows?${getCapabilitiesParams.toString()}`;
    cached = fetch(URL)
      .then(response => response.text())
      .then(responseText => xml.read(responseText, true) as WMSCapabilities)
      .then(capabilities => capabilities?.WMS_Capabilities.Capability.Layer.Layer);
  }
  return(cached);
};

const getLayerTimeDimension = (layerName: string): Promise<WMSDimension | undefined> =>
  getWmsLayers().then(layers => {
    const layer = layers.find(layer => layer.Name === layerName);
    if (layer?.Dimension?.name === 'time') {
      return layer.Dimension;
    }
  });

export const getLayerDates = (layerName: string): Promise<Array<string> | undefined> =>
  getLayerTimeDimension(layerName).then(timeDimension =>
    timeDimension ? timeDimension['#text'].split(',').map(isoDateTime => isoDateTime.split('T')[0]) : undefined
  );

export const getLayerDefaultDate = (layerName: string): Promise<string | undefined> =>
  getLayerTimeDimension(layerName).then(timeDimension =>
    timeDimension ? timeDimension['default'].split('T')[0] : undefined
  );
