import {GEOSERVER_WFS_URL} from '../../config';
import useFetch from '../useFetch';
import {ApiHook} from '../api/types';
import {FeatureCollection, SectorFeature} from './types';
import {MapEntity} from '../../types/commonTypes';

type Params = {
  sectorId: number
}

const useSectorFeature: ApiHook<Params, SectorFeature> = ({
  sectorId
}) => {
  const URL = sectorId !== undefined ?
    `${GEOSERVER_WFS_URL(MapEntity.SECTOR)}&srsName=EPSG:4326&featureID=${sectorId}` :
    undefined;
  const {data} = useFetch<FeatureCollection<SectorFeature>>(URL);
  return data?.features?.length ? data.features[0] : undefined;
};

export default useSectorFeature;
