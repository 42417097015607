import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import styled from '@mui/styles/styled';
import Box from '@mui/material/Box';
import GeoJSON from 'geojson';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import {Layer, Map, MapRef, Popup, Source, ViewStateChangeEvent} from 'react-map-gl';
import {DeckGLProps} from '@deck.gl/react/typed';

import {GEOSERVER_BASE_URL, MAPSTYLES} from '../../config';
import {MapEntity, Variable} from '../../types/commonTypes';
import usePosition from '../../hooks/usePosition';
import useLandparcelVersion from '../../hooks/api/useLandparcelVersion';
import useDeckLayers from '../../hooks/useDeckLayers';
import useCurrentDate from '../../hooks/api/useCurrentDate';
import ColorRampLegend from '../../components/ColorRampLegend';
import LandparcelPopupContent from '../../components/LandparcelPopupContent';
import DeckGLOverlay from '../../components/DeckGLOverlay';
import Disclaimer from '../../components/Disclaimer';
import ZoomToExtentButton from '../../components/ZoomToExtentButton';
import useProfile from '../../hooks/useProfile';

const PopupInfo = styled(Popup)({
  cursor: 'default',
  '& .mapboxgl-popup-content': {
    padding: 0
  }
});

const legendSx = {
  position: 'relative',
  bottom: '70px',
  left: '10px',
  width: 256
};

type SelectedFeatureType = GeoJSON.Feature<GeoJSON.MultiPolygon, {
  id: number,
  clicked: {
    latitude: number,
    longitude: number
  },
  timeseries: Record<string, number>,
  state: Record<string, number>}>;

interface MainContentProps {
  initialPosition?: string,
  onPositionChanged: (position: string) => void,
  mapStyle: string,
  metaverseId?: number,
  year?: number,
  currentYear?: number,
  mapEntity: MapEntity,
  variable: string,
  variableConfig?: Variable,
  date?: string
}

const MainContent: FC<MainContentProps> = ({
  initialPosition,
  onPositionChanged,
  mapStyle,
  metaverseId,
  year,
  currentYear,
  mapEntity,
  variable,
  variableConfig,
  date
}) => {
  const profile = useProfile();
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  const {position, viewport, setViewport} = usePosition(initialPosition);
  const [selectedFeature, setSelectedFeature] = useState<SelectedFeatureType>();
  const currentDate = useCurrentDate({metaverseId, variableConfig});

  const isCurrentYear = currentYear !== undefined && year !== undefined && currentYear === year;

  const deckLayers = useDeckLayers(mapEntity, variableConfig, date, currentDate, metaverseId, year, isCurrentYear);

  useEffect(() => {
    if (selectedFeature && mapEntity === MapEntity.SECTOR) {
      navigateToSectorDetail(selectedFeature.properties.id);
    } else if(selectedFeature && mapEntity === MapEntity.DISTRICT){
      navigateToDistrictDetail(selectedFeature.properties.id);
    }
  }, [selectedFeature]);

  const handleViewportChange = ({viewState: {latitude, longitude, zoom}}: ViewStateChangeEvent)  => {
    const newViewport = {
      latitude,
      longitude,
      zoom
    };
    setViewport(newViewport);
    position && onPositionChanged(position);
  };

  const deckProps: Omit<DeckGLProps, 'style' | 'ref' | 'layers' | 'controller' | 'viewState' | 'onViewStateChange' | 'onWebGLInitialized' | 'glOptions' | 'onResize'> = useMemo(() => ({
    onClick: ({coordinate, object: feature}) => {
      coordinate && feature && setSelectedFeature({
        ...feature,
        properties: {
          ...feature.properties,
          clicked: {
            latitude: coordinate[1],
            longitude: coordinate[0]
          }
        }
      });
    },
    getCursor: ({
      isDragging,
      isHovering
    }: { isDragging: boolean, isHovering: boolean }) => (isDragging ? 'grabbing' : (isHovering ? 'pointer' : 'grab')),
    getTooltip: ({object: feature}: { object?: SelectedFeatureType }) => !selectedFeature && feature && !variableConfig?.isRaster ? {
      html: `<small>
        <div>${t(`variable.${variable}`)}</div>
        <div><b>
          ${date === currentDate && variableConfig && feature.properties.state ? feature.properties.state[variableConfig.apiFieldName] : date && feature.properties.timeseries ? feature.properties.timeseries[date] : '--'}
          ${(variableConfig?.mapUnits || variableConfig?.units) ? ` ${t(`unit.${variableConfig.mapUnits ?? variableConfig.units}`)}` : ''}
        </b></div>
      </small>`
    } : null
  }), [date, selectedFeature, variableConfig]);

  const landparcelVersion = useLandparcelVersion(year !== undefined && mapEntity === MapEntity.LANDPARCEL ? {
    metaverseId,
    landparcelId: selectedFeature?.properties.id,
    year: year,
    lang: i18n.language
  } : {});

  const navigateToLandpacelDetail = (landparcelId: number) => () => {
    navigate(`../landparcel/${landparcelId}/metaverse/${metaverseId}/year/${year}`);
  };

  const navigateToSectorDetail = (sectorId: number) => {
    navigate(`../sector/${sectorId}/metaverse/${metaverseId}`);
  };

  const navigateToDistrictDetail = (districtId: number) => {
    navigate(`../district/${districtId}/metaverse/${metaverseId}`);
  };

  const mapStyleUrl = useMemo(() => MAPSTYLES.find(style => style.id === mapStyle)?.url, [mapStyle]);

  const legendTitle = useMemo(() => t(`variable.${variable}`) + ((variableConfig?.mapUnits || variableConfig?.units) ? ` (${t(`unit.${variableConfig.mapUnits ?? variableConfig.units}`)})` : ''), [variableConfig]);

  const mapRef = useRef<MapRef>(null);

  const rasterUrl = useMemo(() => variableConfig?.isRaster ? [
    `${GEOSERVER_BASE_URL}/wms?bbox={bbox-epsg-3857}&format=image/png8&service=WMS&transparent=true&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&layers=${variableConfig.apiFieldName}&time=${date}`
  ] : [], [variableConfig, date]);

  const zoomToExtent = useCallback(() => {
    if (profile && mapRef.current) {
      const [xMin, yMin, xMax, yMax] = profile.dataExtent;
      mapRef.current?.fitBounds(
        [[xMin, yMin], [xMax, yMax]],
        {padding: 20, duration: 500}
      );
    }
  }, [profile, mapRef.current]);

  return viewport ? <>
    <Disclaimer/>
    <Box sx={{height: '100%'}}>
      <Map
        ref={mapRef}
        antialias
        mapStyle={mapStyleUrl}
        reuseMaps={false}
        styleDiffing={true}
        mapLib={maplibregl}
        RTLTextPlugin=''
        {...viewport}
        onMove={handleViewportChange}
      >
        {variableConfig?.isRaster && <>
          <Source
            key={variableConfig.apiFieldName + date}
            id="raster-source"
            type="raster"
            tiles={...rasterUrl}
            tileSize={256}
          >
          </Source>
          <Layer
            id="raster-layer"
            type="raster"
            source="raster-source"
          />
        </>}
        <DeckGLOverlay
          interleaved={true}
          layers={deckLayers}
          {...deckProps}
        />
        {mapEntity === MapEntity.LANDPARCEL && landparcelVersion && selectedFeature && <PopupInfo
          latitude={selectedFeature.properties.clicked.latitude}
          longitude={selectedFeature.properties.clicked.longitude}
          maxWidth="500"
          closeButton={false}
          closeOnClick={false}
          anchor="top"
        >
          <LandparcelPopupContent
            landparcelVersion={landparcelVersion}
            onDetailClick={navigateToLandpacelDetail(selectedFeature.properties.id)}
            onClose={() => setSelectedFeature(undefined)}
          />
        </PopupInfo>}
      </Map>
      <ZoomToExtentButton onClick={zoomToExtent}/>
      {variableConfig ? <ColorRampLegend
        sx={legendSx}
        colorScheme={variableConfig.colorScheme}
        reverse={variableConfig.reverseColor}
        domain={variableConfig.domain}
        title={legendTitle}
      /> : null}
    </Box>
  </> : <>Error: Map Viewport not available.</>;
};

export default MainContent;
