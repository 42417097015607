import React, {FC} from 'react';

import {useTranslation} from 'react-i18next';
import WatercatchIcon from '@mui/icons-material/Water';

import ButtonGroupList from '@geomatico/geocomponents/Forms/ButtonGroupList';

import {LANDPARCEL_VARIABLES, SECTOR_VARIABLES} from '../config';

import IrrigationIcon from './icons/IrrigationIcon';
import EvapotranspirationIcon from './icons/EvapotranspirationIcon';
import {MapEntity} from '../types/commonTypes';

export interface VariablePickerProps {
  mapEntity: MapEntity,
  selectedVariable: string,
  onSelectedVariableChange: (_variable: string)=> void,
  isCurrentDate: boolean
}
const VariablePicker: FC<VariablePickerProps> = ({mapEntity, selectedVariable, onSelectedVariableChange}) =>  {
  const {t} = useTranslation();

  const variables = mapEntity === MapEntity.LANDPARCEL ? LANDPARCEL_VARIABLES : SECTOR_VARIABLES;

  const aggregates = [...new Set(
    Object.keys(variables)
      .filter(variable => variables[variable].isMain) /*&& (isCurrentDate || variables[variable].hasRecord))*/ // TODO disable variable buttons where !currentDate && !hasRecord
      .map(variable => variable.split('-')[0]) // TODO this only checks for instant variables (so it acts in whole rows)
  )].map(aggregate => ({
    id: aggregate,
    description: <div>{t(`aggregate.${aggregate}`)}</div>
  }));

  const magnitudes = [{
    id: 'et',
    content: <EvapotranspirationIcon/>,
    label: t('magnitude.et')
  }, {
    id: 'irr',
    content: <IrrigationIcon/>,
    label: t('magnitude.irr')
  },
  ...(mapEntity !== MapEntity.LANDPARCEL ? [{
    id: 'watercatch',
    content: <WatercatchIcon/>,
    label: t('magnitude.watercatch')
  }] : [])
  ];

  return (
    <ButtonGroupList
      sx={{fontSize: '14px'}}
      buttonGroupItems={magnitudes}
      categories={aggregates}
      selectedItemId={selectedVariable}
      onItemClick={itemId => itemId !== undefined && onSelectedVariableChange(itemId)}
    />
  );
};

export default VariablePicker;
