import React, {useEffect} from 'react';
import {HashRouter, Route, Routes, useParams} from 'react-router-dom';
import {Outlet, Navigate} from 'react-router-dom';
import MapView from '../views/MapView';
import LandparcelView from '../views/LandparcelView';
import GeoservicesView from '../views/GeoservicesView';
import SectorView from '../views/SectorView';
import DistrictView from '../views/DistrictView';
import {useTranslation} from 'react-i18next';

const LangSetter = () => {
  const {lang} = useParams();
  const {i18n} = useTranslation();

  useEffect(() => {
    if (i18n.resolvedLanguage !== lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang]);

  return <Outlet/>;
};

const AppRoutes = () => {
  const {i18n: {resolvedLanguage}} = useTranslation();
  return <HashRouter>
    <Routes>
      <Route path=":lang" element={<LangSetter/>}>
        <Route path="" element={<Navigate to="map"/>}/>
        <Route path="map/:position/variable/:variable/metaverse/:metaverseIdStr/year/:yearStr/date/:date" element={<MapView/>}/>
        {/*<Route path="map/:position/variable/:variable/metaverse/:metaverseIdStr/date/:date" element={<MapView/>}/>*/}
        <Route path="map/:position/variable/:variable/metaverse/:metaverseIdStr/year/:yearStr" element={<MapView/>}/>
        <Route path="map/:position/variable/:variable/metaverse/:metaverseIdStr" element={<MapView/>}/>
        <Route path="map/:position/variable/:variable" element={<MapView/>}/>
        <Route path="map" element={<MapView/>}/>
        <Route path="landparcel/:landparcelIdStr/metaverse/:metaverseIdStr/year/:yearStr" element={<LandparcelView/>}/>
        <Route path="sector/:sectorIdStr/metaverse/:metaverseIdStr" element={<SectorView/>}/>
        <Route path="district/:districtIdStr/metaverse/:metaverseIdStr" element={<DistrictView/>}/>

        <Route path="geoservices" element={<GeoservicesView/>}/>
        <Route path="*" element={<>404</>}/>
      </Route>
      <Route path="*" element={<Navigate to={resolvedLanguage}/>}/>
    </Routes>
  </HashRouter>;
};

export default AppRoutes;
