import React, {FC} from 'react';

import {useTranslation} from 'react-i18next';
import Table from '@mui/material/Table';
import grey from '@mui/material/colors/grey';
import TableBody from '@mui/material/TableBody';
import styled from '@mui/material/styles/styled';
import TableRow from '@mui/material/TableRow';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import {LandparcelState} from '../hooks/api/useLandparcelState';
import {SectorState} from '../hooks/api/useSectorState';
import {Variable} from '../types/commonTypes';

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  },
}));

const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export interface OtherVariablesTableProps {
  state: LandparcelState | SectorState,
  variables: Record<string, Variable>
}

const OtherVariablesTable: FC<OtherVariablesTableProps> = ({state, variables}) => {
  const {t} = useTranslation();
  const otherVariables = Object.entries(variables)
    .filter(([,el]) => !el.isMain)
    .map(([k, v]) => ({id: k, ...v}));

  return (
    <Table sx={{border: `1px solid ${grey[300]}`}} size="small">
      <TableBody>
        {otherVariables.map(({id, apiFieldName, units}) => (
          state[apiFieldName as keyof (LandparcelState | SectorState)] !== null && (<StyledTableRow key={id}>
            <StyledTableCell align="left">{t(`variable.${id}`)}</StyledTableCell>
            <StyledTableCell align="center">{(state[apiFieldName as keyof (LandparcelState | SectorState)] as number)?.toFixed(1)} {units ? t(`unit.${units}`) : ''}</StyledTableCell>
          </StyledTableRow>)
        ))}
      </TableBody>
    </Table>
  );
};

export default OtherVariablesTable;
