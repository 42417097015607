import {GEOSERVER_WFS_URL} from '../../config';
import useFetch from '../useFetch';
import {ApiHook} from '../api/types';
import {FeatureCollection, DistrictFeature} from './types';
import {MapEntity} from '../../types/commonTypes';

type Params = {
  districtId: number
}

const useDistrictFeature: ApiHook<Params, DistrictFeature> = ({
  districtId
}) => {
  const URL = districtId !== undefined ?
    `${GEOSERVER_WFS_URL(MapEntity.DISTRICT)}&srsName=EPSG:4326&featureID=${districtId}` :
    undefined;
  const {data} = useFetch<FeatureCollection<DistrictFeature>>(URL);
  return data?.features?.length ? data.features[0] : undefined;
};

export default useDistrictFeature;
