import {GEOSERVER_WFS_URL} from '../config';
import {MapEntity} from '../types/commonTypes';
import {SectorTimeseries} from '../hooks/api/useSectorTimeseries';
import {ChartTimeseries} from '../hooks/api/types';
import {DistrictTimeseries} from '../hooks/api/useDistrictTimeseries';
import {FeatureCollection, SectorFeature} from '../hooks/geoserver/types';
import {MapState} from './mapData/loadState';
import {MapTimeseries} from './mapData/loadTimeseries';

export type SectorsByDistrict = Record<number, Array<number>>;

// Función que agrupa por district_id. Interna, solo se exporta para los tests
export const groupSectorsByDistrict = (properties: Array<{ id: number, district_id: number, name: string }>) => {
  return properties.reduce((acc: Record<number, Array<number>>, properties) => {
    const {id: sector_id, district_id} = properties;
    if (!acc[district_id]) acc[district_id] = [];
    acc[district_id].push(sector_id);
    return acc;
  }, {});
};

export const getSectorsByDistrict = async (): Promise<SectorsByDistrict> => {
  const URL = GEOSERVER_WFS_URL(MapEntity.SECTOR);
  const response = await fetch(URL);
  const fc: FeatureCollection<SectorFeature> = await response.json();
  const properties = fc.features.map(feature => feature.properties);
  return groupSectorsByDistrict(properties);
};

const truncDecimals = (number: number, decimals = 5) => parseFloat(number.toFixed(decimals));

export const groupByDistrict = <T extends MapState | MapTimeseries> (stateOrTimeseries: T, sectorsByDistrict: SectorsByDistrict): T => {
  return {
    ...stateOrTimeseries,
    data: Object.entries(sectorsByDistrict).reduce((data, [districtId, sectorsInDistrict]) => {
      data[districtId] = sectorsInDistrict
        .map(sectorId => stateOrTimeseries.data[sectorId])
        .reduce((districtValues, sectorValues) => {
          if(sectorValues !== undefined) {
            districtValues = districtValues.length ? districtValues.map((d, i) => d + sectorValues[i]) : [...sectorValues];
          }
          return districtValues;
        }, [] as Array<number>)
        .map(value => truncDecimals(value));
      return data;
    }, {} as Record<string, Array<number>>)
  };
};

const chartTimeseriesReducer = (sum: ChartTimeseries, item: ChartTimeseries, index: number) => {
  if (index === 0) {
    return {...item};
  } else {
    return {
      record: sum.record.map(({date, value}, i) => ({
        date: date,
        value: truncDecimals(value + (item.record[i]?.value || 0))
      })),
      state: {
        date: sum.state.date,
        value: truncDecimals(sum.state.value + item.state.value)
      },
      forecast: sum.forecast.map(({date, min, max}, i) => ({
        date: date,
        min: truncDecimals(min + (item.forecast[i]?.min || 0)),
        max: truncDecimals(max + (item.forecast[i]?.max || 0))
      }))
    };
  }
};

export const sumTimeseries = (sectorsTimeseries: Array<SectorTimeseries>): DistrictTimeseries => {
  return {
    irr: sectorsTimeseries.map(timeseries => timeseries.irr).reduce(chartTimeseriesReducer),
    accum_irr: sectorsTimeseries.map(timeseries => timeseries.accum_irr).reduce(chartTimeseriesReducer),
    watercatch: sectorsTimeseries.map(timeseries => timeseries.watercatch).reduce(chartTimeseriesReducer),
    accum_watercatch: sectorsTimeseries.map(timeseries => timeseries.accum_watercatch).reduce(chartTimeseriesReducer)
  };
};
