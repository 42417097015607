import {Reducer, useEffect, useReducer} from 'react';
import {useTranslation} from 'react-i18next';

type IrriterError = {
  message: string,
  status?: string,
  response?: boolean
}

type State<T> = {
  isWaiting: boolean,
  data?: T,
  error?: IrriterError,
}

type Action<T> = {
  type: 'REQUEST' | 'SUCCESS' | 'ERROR',
  payload?: T | undefined | IrriterError
}

const initialState = {
  isWaiting: false
};

const reducer = <T> (state: State<T>, action: Action<T>) => {
  switch (action.type) {
  case 'REQUEST':
    return {...initialState, isWaiting: true};
  case 'SUCCESS':
    return {...initialState, isWaiting: false, data: action.payload as T};
  case 'ERROR':
    return {...initialState, isWaiting: false, error: action.payload as IrriterError};
  default:
    return state;
  }
};

const cache: Record<string, Promise<unknown>> = {};

const useFetch = <T> (url?: string, options?: RequestInit, cacheResponse = true, parseJSON = true): State<T> => {
  const {t} = useTranslation();
  const [state, dispatch] = useReducer<Reducer<State<T>, Action<T>>>(reducer, initialState);

  useEffect(() => {
    if (url === undefined) {
      dispatch({type: 'SUCCESS', payload: undefined});
    } else {
      dispatch({type: 'REQUEST'});
      if (cacheResponse && cache[url] !== undefined) {
        // Cache hit!
        cache[url].then(response => {
          dispatch({type: 'SUCCESS', payload: response as T | undefined});
        }).catch((error) => {
          dispatch({type: 'ERROR', payload: error as IrriterError});
        });
      } else {
        const request = fetch(url, {
          method: options?.method || 'GET',
          body: options?.body ? JSON.stringify(options?.body) : options?.body,
          headers: {
            'Content-Type': 'application/json'
          }
        })
          .then(response => {
            if (response.status === 204) {
              return Promise.resolve(undefined);
            } else if (response.status >= 200 && response.status < 300 && response.ok) {
              return (parseJSON ? response.json() : response.text()) as Promise<T>;
            } else {
              return (parseJSON ? response.json() : response.text()).then(responseBody =>
                Promise.reject({
                  message: (response.status >= 400 && response.status <= 500) ?
                    t('httpErrors.' + response.status.toString()) :
                    response.statusText,
                  status: response.status,
                  response: responseBody
                })
              );
            }
          })
          .then((response: T | undefined) => {
            dispatch({type: 'SUCCESS', payload: response});
            return response;
          })
          .catch((error: IrriterError) => {
            dispatch({type: 'ERROR', payload: error});
            return Promise.reject(error);
          });
        if (cacheResponse) {
          cache[url] = request;
        }
      }
    }
  }, [url]);

  return url ? state : initialState;
};

export default useFetch;
