import {useEffect, useState} from 'react';
import {ApiHook} from '../api/types';
import {SectorTimeseries} from '../api/useSectorTimeseries';
import {sumTimeseries, getSectorsByDistrict} from '../../utils/districtUtils';
import {API_BASE_URL} from '../../config';

type Params = {
  metaverseId: number,
  districtId: number
};

export type DistrictTimeseries = SectorTimeseries;

const useDistrictTimeseries: ApiHook<Params, DistrictTimeseries | undefined> = ({metaverseId, districtId}) => {
  const [districtTimeseries, setDistrictTimeseries] = useState<DistrictTimeseries>();

  useEffect( () => {
    getSectorsByDistrict().then(sectorsByDistrict => {
      if (sectorsByDistrict && districtId) {
        Promise.all(
          sectorsByDistrict[districtId].map(sectorId =>
            fetch(`${API_BASE_URL}/metaverses/${metaverseId}/districtsectors/${sectorId}/timeseries/`)
              .then(response => response.json())
          )
        ).then(sectorTimeseries => {
          setDistrictTimeseries(sumTimeseries(sectorTimeseries));
        });
      }
    });
  }, []);

  return districtTimeseries;
};

export default useDistrictTimeseries;
