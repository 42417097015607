import {API_BASE_URL} from '../../config';
import useFetch from '../useFetch';
import {ApiHook} from './types';

type Params = {
  metaverseId: number,
  sectorId: number
}

export type SectorState = {
  day: string,
  accum_et: number,
  accum_irr: number,
  accum_watercatch: number,
  et: number,
  irr: number,
  watercatch: number,
  p75_pending_et: number,
  p75_pending_irr: number,
  p75_pending_watercatch: number,
  sum_area: number
};

const useSectorState: ApiHook<Params, SectorState> = ({
  metaverseId,
  sectorId
}) => {
  const URL = metaverseId !== undefined && sectorId !== undefined ?
    `${API_BASE_URL}/metaverses/${metaverseId}/districtsectors/${sectorId}/state/` :
    undefined;
  const {data} = useFetch<SectorState>(URL);
  return data;
};

export default useSectorState;
