import {useCallback, useEffect, useMemo, useState} from 'react';
import GeoJSON from 'geojson';
import {GeoBoundingBox, TileLayer} from '@deck.gl/geo-layers/typed';
import {GeoJsonLayer} from '@deck.gl/layers/typed';
import {_TileLoadProps} from '@deck.gl/geo-layers/typed';
import {ClipExtension} from '@deck.gl/extensions/typed';
import useColorRamp from '@geomatico/geocomponents/hooks/useColorRamp';
import {LANDPARCEL_MIN_ZOOM} from '../config';
import {MapEntity, TileIndex, TileParams, Variable} from '../types/commonTypes';
import useProfile from './useProfile';
import loadMvt from '../utils/mapData/loadMvt';
import loadWfs from '../utils/mapData/loadWfs';
import loadTimeseries from '../utils/mapData/loadTimeseries';
import loadState from '../utils/mapData/loadState';
import buildIrriterFeatures from '../utils/mapData/buildIrriterFeatures';
import LabeledGeoJsonLayer from '../components/LabeledGeojsonLayer';


type RGBColor = [number, number, number] | [number, number, number, number];

const DEFAULT_COLOR: RGBColor = [255, 255, 255, 0];
const LINE_COLOR: RGBColor = [80, 80, 80];

const useDeckLayers = (mapEntity: MapEntity, variableConfig?: Variable, date?: string, currentDate?: string, metaverseId?: number, year?: number, isCurrentYear?: boolean) => {
  const profile = useProfile();

  const [readyTiles, setReadyTiles] = useState<Array<TileIndex>>([]);
  const setReadyTile = useCallback((tileIndex: TileIndex) => {
    setReadyTiles(prev => [...prev, tileIndex]);
  }, []);
  useEffect(()=> {
    setReadyTiles([]);
  }, [mapEntity, metaverseId, variableConfig]);

  const untiledData = useMemo(async (): Promise<GeoJSON.FeatureCollection> => {
    if (mapEntity === MapEntity.LANDPARCEL || metaverseId === undefined || variableConfig === undefined || year === undefined || isCurrentYear === undefined) {
      return Promise.resolve({type: 'FeatureCollection', features: []});
    } else {
      const tileParams: TileParams = {
        metaverseId,
        mapEntity,
        variableConfig
      };

      const [features, timeseriesData, stateData] = await Promise.all([
        loadWfs(tileParams),
        loadTimeseries(tileParams, year),
        loadState(tileParams, isCurrentYear && !variableConfig.isRaster)
      ]);
      return {type: 'FeatureCollection', features: buildIrriterFeatures(features, timeseriesData, stateData)};
    }
  }, [mapEntity, metaverseId, variableConfig, year, isCurrentYear]);

  const getTileData = useCallback(async (tile: _TileLoadProps) => {
    if (metaverseId === undefined || variableConfig === undefined || year === undefined || isCurrentYear === undefined) {
      return [];
    } else {
      const tileIndex = {
        x: tile.index.x,
        y: tile.index.y,
        z: tile.index.z
      };
      const tileParams: TileParams = {
        metaverseId,
        mapEntity,
        variableConfig,
        tileIndex,
        signal: tile.signal
      };

      return Promise.all([
        loadMvt(tileParams),
        loadTimeseries(tileParams, year),
        loadState(tileParams, isCurrentYear && !variableConfig.isRaster)
      ]).then(([features, timeseriesData, stateData]) => {
        if (tile.signal?.aborted) {
          return null;
        }
        setReadyTile(tileIndex);
        return buildIrriterFeatures(features, timeseriesData, stateData, tileIndex);
      });
    }
  }, [mapEntity, metaverseId, variableConfig, year, isCurrentYear]);

  const getColor = useCallback(
    (value: number): RGBColor => {
      if (!variableConfig) return DEFAULT_COLOR;
      const {d3ScaleInt} = useColorRamp(variableConfig.colorScheme, variableConfig.domain, variableConfig.reverseColor);
      return d3ScaleInt(value) as RGBColor;
    },
    [variableConfig]
  );

  const getFillColor = useCallback(
    (feature) => {
      if(feature.properties.tileIndex && !readyTiles.includes(feature.properties.tileIndex)) return DEFAULT_COLOR;
      const value = date === currentDate && variableConfig && feature.properties.state ? feature.properties.state[variableConfig.apiFieldName] : date && feature.properties.timeseries ? feature.properties.timeseries[date] : undefined;
      return value !== undefined && !isNaN(value) ? getColor(value) : DEFAULT_COLOR;
    }
    , [date, currentDate, variableConfig?.apiFieldName, readyTiles]);

  const renderSublayers = useCallback((props) => {
    const {west, south, east, north} = props.tile.bbox as GeoBoundingBox;
    return new GeoJsonLayer({
      ...props,
      extensions: [new ClipExtension()],
      clipBounds: [west, south, east, north]
    });
  }, []);

  return useMemo(() => ([
    mapEntity === MapEntity.LANDPARCEL ? // Tiled queries for landparcels
      new TileLayer({
        id: 'landparcels',
        minZoom: LANDPARCEL_MIN_ZOOM,
        maxZoom: LANDPARCEL_MIN_ZOOM,
        extent: profile?.dataExtent,
        getTileData: getTileData,
        renderSubLayers: renderSublayers,
        lineWidthMinPixels: 1,
        getLineColor: LINE_COLOR,
        getFillColor: getFillColor,
        updateTriggers: {
          getFillColor: [getFillColor],
          getTileData: [getTileData]
        },
        pickable: true,
        //autoHighlight: true
      }) :
      new LabeledGeoJsonLayer({ // Untiled & labelled queries for districts or sectors
        id: 'districtsOrSectors',
        data: untiledData,
        lineWidthMinPixels: 1,
        getLineColor: LINE_COLOR,
        getFillColor: getFillColor,
        updateTriggers: {
          getFillColor: [getFillColor]
        },
        getLabel: (f: GeoJSON.Feature) => f?.properties?.name,
        getLabelSize: 12,
        fontFamily: 'system-ui',
        pickable: true,
        //autoHighlight: true
      })
  ]), [mapEntity, getTileData, untiledData, getFillColor]);
};

export default useDeckLayers;
