import {API_BASE_URL} from '../../config';
import useFetch from '../useFetch';
import {ApiHook} from './types';

type Params = {
  metaverseId: number,
  landparcelId: number
}

export type LandparcelState = {
  cwsi: number,
  p75_pending_et: number,
  p75_pending_irr: number,
  p50_pending_dr: number,
  accum_et: number,
  accum_dr: number,
  fapar_all: number | null,
  fpotyield: number,
  irr: number,
  fapar: number | null,
  t_crop: number,
  asw: number,
  et: number,
  accum_irr: number,
  day: string
};

const useLandparcelState: ApiHook<Params, LandparcelState> = ({
  metaverseId,
  landparcelId
}) => {
  const URL = metaverseId !== undefined && landparcelId !== undefined ?
    `${API_BASE_URL}/metaverses/${metaverseId}/landparcels/${landparcelId}/state/` :
    undefined;
  const {data} = useFetch<LandparcelState>(URL);
  return data;
};

export default useLandparcelState;
