import React, {FC} from 'react';

import {useTranslation} from 'react-i18next';
import Table from '@mui/material/Table';
import grey from '@mui/material/colors/grey';
import TableBody from '@mui/material/TableBody';
import styled from '@mui/material/styles/styled';
import TableRow from '@mui/material/TableRow';
import TableCell, {tableCellClasses} from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import {LandparcelState} from '../hooks/api/useLandparcelState';
import {SectorState} from '../hooks/api/useSectorState';
import {Variable} from '../types/commonTypes';
import {DistrictState} from '../hooks/api/useDistrictState';

const StyledTableRow = styled(TableRow)(({theme}) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0
  },
}));

const StyledTableCell = styled(TableCell)(({theme}) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export interface MainVariablesTableProps {
  state: LandparcelState | SectorState | DistrictState,
  variables: Record<string, Variable>
}

export interface Rows {
  key: string,
  title: string,
  et: string,
  irr: string,
  watercatch?: string
}

const MainVariablesTable: FC<MainVariablesTableProps> = ({state, variables}) => {
  const {t} = useTranslation();
  const isSector = !!variables['accum-watercatch'];

  const aggregates: Array<string> = [...new Set(  // ['accum', 'current', 'p75_pending',]
    (Object.keys(variables) as Array<string>)
      .filter((variable) => variables[variable].isMain)
      .map(variable => variable.split('-')[0])
  )];

  const rows = state ? aggregates.map(aggregate => {

    const et = variables[`${aggregate}-et`];
    const irr = variables[`${aggregate}-irr`];

    const res: Rows = {
      key: aggregate,
      title: t(`aggregate.${aggregate}`),
      // @ts-ignore Acceso a key de objeto con string
      et: `${state?.[et.apiFieldName].toFixed(1)} ${t(`unit.${irr.units}`)}`,
      // @ts-ignore Acceso a key de objeto con string
      irr: `${(state[irr.apiFieldName]).toFixed(1)} ${t(`unit.${irr.units}`)}`
    };
    if (isSector) {
      const watercatch = variables[`${aggregate}-watercatch`];
      // @ts-ignore Acceso a key de objeto con string
      res.watercatch = `${(state[watercatch.apiFieldName]).toFixed(1)} ${t(`unit.${watercatch.units}`)}`;
    }

    return res;
  }) : [];

  return (
    <Table sx={{height: '100%', border: `1px solid ${grey[300]}`}} size="small" aria-label="data table">
      <TableHead>
        <StyledTableRow>
          <StyledTableCell align="left"/>
          <StyledTableCell align="center" sx={{fontWeight: 'bold'}}>{t('magnitude.et')}</StyledTableCell>
          <StyledTableCell align="center" sx={{fontWeight: 'bold'}}>{t('magnitude.irr')}</StyledTableCell>
          {
            isSector &&
            <StyledTableCell align="center" sx={{fontWeight: 'bold'}}>{t('magnitude.watercatch')}</StyledTableCell>
          }
        </StyledTableRow>
      </TableHead>
      <TableBody>
        {rows.map((row) => (
          <StyledTableRow
            key={row.key}
            sx={{'&:last-child td, &:last-child th': {border: 0}}}
          >
            <StyledTableCell align="left">{row.title}</StyledTableCell>
            <StyledTableCell align="center">{row.et}</StyledTableCell>
            <StyledTableCell align="center">{row.irr}</StyledTableCell>
            {
              isSector &&
              <StyledTableCell align="center">{row.watercatch}</StyledTableCell>
            }
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default MainVariablesTable;
