import GeoJSON from 'geojson';
import polylabel from 'polylabel';
import area from '@turf/area';
import {polygon} from '@turf/helpers';

const MIN_LABELLED_AREA = 100000; // Don't label polygons less than 10ha

const getMultiPolygonAnchors = (coordinates: GeoJSON.Position[][][]) => {
  const polygons = coordinates.map(rings => polygon(rings));
  const areas = polygons.map(area); // Area in m²
  const maxArea = Math.max.apply(null, areas);
  // Filter out the areas that are too small
  return polygons.filter((f, index) => areas[index] > maxArea * 0.5 && areas[index] > MIN_LABELLED_AREA).map(f => polylabel(f.geometry.coordinates));
};

const getLabelAnchors = (geometry: GeoJSON.Geometry): Array<GeoJSON.Position> => {
  switch (geometry.type) {
  case 'Point':
    return [geometry.coordinates];
  case 'MultiPoint':
    return geometry.coordinates;
  case 'Polygon':
    return [polylabel(geometry.coordinates)];
  case 'MultiPolygon':
    return getMultiPolygonAnchors(geometry.coordinates);
  default:
    return [];
  }
};

export default getLabelAnchors;
