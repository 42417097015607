import {API_BASE_URL} from '../../config';
import {ApiHook} from './types';
import {MapEntity, Variable} from '../../types/commonTypes';
import {useEffect, useState} from 'react';
import {getLayerDates} from '../../utils/wmsLayers';

type Params = {
  metaverseId: number,
  mapEntity: MapEntity,
  variableConfig: Variable,
  year: number
}

export type Dates = Array<string>;

export type ApiResponse = {
  dates: Dates,
  data: Record<string, never>
};

const ENTITY_URL: Record<MapEntity, string> = {
  [MapEntity.LANDPARCEL]: 'landparcels',
  [MapEntity.DISTRICT]: 'districtsectors',
  [MapEntity.SECTOR]: 'districtsectors' // No endpoint for sectors, dates are same as in districts
};

const EMPTY_TILE_INDEX = '13,0,0'; // Hack: By querying timeseries with an out-of-BBOX tile, we get only the dates array

const useDates: ApiHook<Params, Dates> = ({
  metaverseId, mapEntity, variableConfig, year
}) => {
  const [dates, setDates] = useState<Dates>();

  useEffect(() => {
    if(metaverseId === undefined || mapEntity === undefined || year === undefined || !variableConfig?.hasRecord) {
      setDates(undefined);
    } else if (variableConfig.isRaster) {
      getLayerDates(variableConfig.apiFieldName).then(setDates);
    } else {
      const URL = `${API_BASE_URL}/metaverses/${metaverseId}/${ENTITY_URL[mapEntity]}/timeseries/?year=${year}&variable=${variableConfig.apiFieldName}&tileindex=${EMPTY_TILE_INDEX}`;
      fetch(URL)
        .then(response => response.json())
        .then((data: ApiResponse) => setDates(data?.dates));
    }

  }, [metaverseId, mapEntity, variableConfig, year]);

  return dates;
};

export default useDates;
