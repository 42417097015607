import React, {FC} from 'react';
import {useTranslation} from 'react-i18next';

import styled from '@mui/material/styles/styled';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Close from '@mui/icons-material/Close';
import Launch from '@mui/icons-material/Launch';
import IconButton from '@mui/material/IconButton';
import {LandparcelVersion} from '../hooks/api/useLandparcelVersion';

const StyledCard = styled(Card)({
  maxWidth: 520,
  border: 'none',
  '& .MuiCardHeader-root': {
    padding: '10px',
    textAlign: 'center'
  },
  '& .MuiCardHeader-title': {
    fontSize: '21px'
  },
  '& .MuiCardContent-root': {
    padding: '0 10px'
  },
  '& .MuiCardActions-root': {
    padding: '10px'
  }
});

export interface LandparcelPopupContentProps {
  landparcelVersion: LandparcelVersion,
  onDetailClick: () => void,
  onClose: () => void,
}

export const LandparcelPopupContent: FC<LandparcelPopupContentProps> = ({landparcelVersion, onDetailClick, onClose}) => {
  const {t} = useTranslation();

  const handleClick = () => onDetailClick && onDetailClick();
  const handleClose = () => onClose && onClose();

  return (
    <StyledCard>
      <CardHeader
        title={`${landparcelVersion.crop1} ${landparcelVersion.crop2 ? ` / ${landparcelVersion.crop2}` : ''}`}
        action={
          <IconButton aria-label="close" onClick={handleClose}>
            <Close/>
          </IconButton>
        }
      />
      <CardContent>
        <Typography variant="subtitle2">
          <div><span style={{fontWeight: 'bold'}}>{t('landparcelVersion.titles.soil_class_id')}: </span>{landparcelVersion.soilclass}</div>
          <div><span
            style={{fontWeight: 'bold'}}>{t('landparcelVersion.titles.irrigation_method_id')}: </span>{landparcelVersion.irrigationmethod}
          </div>
        </Typography>
      </CardContent>
      <CardActions sx={{display: 'flex', justifyContent: 'end'}}>
        <Button variant="contained" onClick={handleClick} endIcon={<Launch/>}>
          {t('landparcelDetail.detail')}
        </Button>
      </CardActions>
    </StyledCard>
  );
};

export default LandparcelPopupContent;
