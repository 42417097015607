import React, {FC} from 'react';

import Button from '@mui/material/Button';
import ZoomToExtentIcon from '@mui/icons-material/ZoomOutMap';

const sx = {
  startIcon: {
    margin: '0'
  },
  position: 'absolute',
  top: 20,
  right: 20,
  padding: '6px',
  minWidth: 0
};

type ZoomToExtentButtonProps = {
  onClick: () => void
}

const ZoomToExtentButton: FC<ZoomToExtentButtonProps> = ({onClick}) => {
  return <Button variant='contained' sx={sx} onClick={onClick}>
    <ZoomToExtentIcon/>
  </Button>;
};

export default ZoomToExtentButton;
