import {load} from '@loaders.gl/core';
import {MVTWorkerLoader} from '@loaders.gl/mvt';

import {TileParams} from '../../types/commonTypes';
import {GEOSERVER_TILES_URL} from '../../config';

const loadMvt = (params: TileParams): Promise<Array<GeoJSON.Feature>> => {
  const {tileIndex, mapEntity} = params;
  if(tileIndex !== undefined) {
    const {z, x, y} = tileIndex;
    const tms_y = (1 << z) - y - 1;
    const mvtUrl = GEOSERVER_TILES_URL(mapEntity)
      .replace('{z}',String(z))
      .replace('{x}',String(x))
      .replace('{-y}',String(tms_y));
    return load(mvtUrl, MVTWorkerLoader, {mvt: {tileIndex, coordinates: 'wgs84'}});
  } else {
    console.error('Failed loading mvt, no TileIndex provided');
    return Promise.resolve([]);
  }
};

export default loadMvt;
