import React from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import Layout from '../components/Layout';
import LandparcelDetail from '../components/LandparcelDetail';
import useLandparcelFeature from '../hooks/geoserver/useLandparcelFeature';
import useLandparcelState from '../hooks/api/useLandparcelState';
import useLandparcelTimeseries from '../hooks/api/useLandparcelTimeseries';
import useYears from '../hooks/api/useYears';
import useLandparcelVersion from '../hooks/api/useLandparcelVersion';

const LandparcelView = () => {
  const {i18n} = useTranslation();
  const navigate = useNavigate();
  const {metaverseIdStr, landparcelIdStr, yearStr} = useParams();

  const metaverseId = metaverseIdStr == undefined ? undefined : ~~metaverseIdStr;
  const landparcelId = landparcelIdStr == undefined ? undefined : ~~landparcelIdStr;
  const year = yearStr == undefined ? undefined : ~~yearStr;
  const lang = i18n.language;

  const feature = useLandparcelFeature({landparcelId});
  const years = useYears({metaverseId});
  const version = useLandparcelVersion({metaverseId, landparcelId, year, lang});
  const state = useLandparcelState({metaverseId, landparcelId});
  const timeseries = useLandparcelTimeseries({metaverseId, landparcelId, year});

  const handleYearChange = (newYear: number | Array<number>) => navigate(`../landparcel/${landparcelId}/metaverse/${metaverseId}/year/${newYear}`);

  const mainContent = <LandparcelDetail
    feature={feature}
    years={years}
    onYearChange={handleYearChange}
    year={year ?? 0}
    version={version}
    state={state}
    timeseries={timeseries}
  />;

  return <Layout
    mainContent={mainContent}
    miniSidePanelSelectedActionId='landparcelView'
  />;
};

export default LandparcelView;
