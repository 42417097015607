import React, {FC, useMemo} from 'react';
import Box from '@mui/material/Box';
import {useTranslation} from 'react-i18next';

import {VegaLite} from 'react-vega';
import {Handler} from 'vega-tooltip';
import {TimeLocale} from 'vega-typings';
import {TopLevelSpec} from 'vega-lite';
import {ChartTimeseries} from '../hooks/api/types';

const languagesConf: {[index: string]: TimeLocale} = {
  en: require('../i18n/d3/d3-time-format-en.json'),
  es: require('../i18n/d3/d3-time-format-es.json'),
  ca: require('../i18n/d3/d3-time-format-ca.json'),
};

interface VegaTooltip {
  date: number,
  current: number,
  maxPrev: number,
  minPrev: number,
  record: number
}

export interface ChartProps {
  data?: ChartTimeseries,
  variable: string,
  units?: string
}

const Chart: FC<ChartProps> = ({data, variable, units}) => {

  const {t, i18n} = useTranslation();
  const currentLang = i18n.resolvedLanguage;

  const unitLabel = units ? t(`unit.${units}`) : '';
  const timeseries = ['record', 'minPrev', 'maxPrev', 'current'];

  const chartData = useMemo(() => ({
    values: data ? [
      // pasado
      ...data.record.map(({date, value}) => ({
        symbol: 'record',
        date,
        value
      })),
      // actual
      ...(data.state ? timeseries.map(series => ({
        symbol: series,
        date: data.state.date,
        value: data.state.value
      })) : []),
      // futuro
      ...data.forecast.flatMap(({date, min, max}) => ([{
        symbol: 'minPrev',
        date,
        value: min
      }, {
        symbol: 'maxPrev',
        date,
        value: max
      }]))
    ] : []
  }), [data]);

  const spec: TopLevelSpec = {
    width: 'container',
    height: 'container',
    data: {name: 'values'},
    config: {
      locale: {
        time: languagesConf[currentLang],
      },
      background: 'white',
      axisX: {
        gridColor: 'lightgrey',
      },
      axisY: {
        gridColor: 'lightgrey',
      },
    },
    encoding: {x: {field: 'date', type: 'temporal', title: ''}},
    layer: [
      {
        encoding: {
          color: {
            field: 'symbol', type: 'nominal',
            legend: null,
          },
          y: {field: 'value', type: 'quantitative', title: `${t(`variable.${variable}`)}${unitLabel ? ` (${unitLabel})` : ''}`
          },
        },
        layer: [
          {mark: 'line'},
          {transform: [{filter: {'param': 'hover', 'empty': false}}], 'mark': 'point'}
        ]
      },
      {
        transform: [{pivot: 'symbol', value: 'value', 'groupby': ['date']}],
        mark: {type: 'rule', tooltip: {content: 'data'}},
        encoding: {
          opacity: {
            condition: {'value': 0.3, param: 'hover', empty: false},
            value: 0
          },
        },
        params: [{
          name: 'hover',
          select: {
            type: 'point',
            fields: ['date'],
            nearest: true,
            on: 'mouseover',
            clear: 'mouseout'
          }
        }]
      },
    ]
  };


  const formatTooltip = (value: VegaTooltip) => {
    const dateOptions: Intl.DateTimeFormatOptions = {year: 'numeric', month: 'short', day: 'numeric'};

    const formatLocaleDate = (value: VegaTooltip, dateOptions: Intl.DateTimeFormatOptions) => new Date(value.date).toLocaleDateString(i18n.resolvedLanguage, dateOptions);

    const parseToTimestamp =(date: string)=> new Date(date).getTime();

    let content = `<table>
       <tr>
         <td>${t('date')}: </td>
         <td>${formatLocaleDate(value, dateOptions)}</td>
       </tr>`;
    if (!data?.state || parseToTimestamp(data.state.date) > value.date) {
      content += `<tr>
         <td>${t('timeseries.record')}: </td>
         <td>${value.record.toFixed(1)} ${unitLabel}</td>
       </tr>`;
    } else if (parseToTimestamp(data.state.date) === value.date) {
      content += `<tr>
         <td>${t('timeseries.state')}: </td>
         <td>${value.current.toFixed(1)} ${unitLabel}</td>
       </tr>`;
    } else {
      content += `<tr>
         <td>${t('timeseries.forecastMin')}: </td>
         <td>${value['minPrev'].toFixed(1)} ${unitLabel}</td>
       </tr>
       <tr>
         <td>${t('timeseries.forecastMax')}: </td>
         <td>${value['maxPrev'].toFixed(1)} ${unitLabel}</td>
       </tr>`;
    }
    return `${content}</table>`;
  };

  const tooltipHandler = new Handler({theme: 'light', formatTooltip: formatTooltip}).call;

  return <Box sx={{width: '100%', height: '100%'}}>
    {
      !!chartData.values.length &&
      <VegaLite spec={spec} tooltip={tooltipHandler} data={chartData} actions={false} style={{margin: '8px', width: 'calc(100% - 16px)', height: 'calc(100% - 16px)', overflow: 'hidden'}}/>
    }
    {!chartData.values.length && <Box sx={{fontStyle: 'italic'}}>{t('timeseries.noData')}</Box>}
  </Box>;
};

export default Chart;
