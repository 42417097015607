import React from 'react';
import {useParams} from 'react-router-dom';

import useDistrictState from '../hooks/api/useDistrictState';
import useDistrictFeature from '../hooks/geoserver/useDistrictFeature';
import useDistrictTimeseries from '../hooks/api/useDistrictTimeseries';

import Layout from '../components/Layout';
import EntityDetail from '../components/EntityDetail';

const DistrictView = () => {
  const {metaverseIdStr, districtIdStr} = useParams();
  const metaverseId = metaverseIdStr == undefined ? undefined : ~~metaverseIdStr;
  const districtId = districtIdStr == undefined ? undefined : ~~districtIdStr;

  const feature = useDistrictFeature({districtId});
  const state = useDistrictState({metaverseId, districtId});
  const timeseries = useDistrictTimeseries({metaverseId, districtId});

  const mainContent = <EntityDetail
    feature={feature}
    state={state}
    timeseries={timeseries}
  />;

  return <Layout
    mainContent={mainContent}
    miniSidePanelSelectedActionId='sectorView'
  />;
};

export default DistrictView;
