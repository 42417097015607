import React from 'react';
import {useParams} from 'react-router-dom';

import Layout from '../components/Layout';
import useSectorFeature from '../hooks/geoserver/useSectorFeature';
import useSectorState from '../hooks/api/useSectorState';
import useSectorTimeseries from '../hooks/api/useSectorTimeseries';
import EntityDetail from '../components/EntityDetail';

const SectorView = () => {
  const {metaverseIdStr, sectorIdStr} = useParams();
  const metaverseId = metaverseIdStr == undefined ? undefined : ~~metaverseIdStr;
  const sectorId = sectorIdStr == undefined ? undefined : ~~sectorIdStr;

  const feature = useSectorFeature({sectorId});
  const state = useSectorState({metaverseId, sectorId});
  const timeseries = useSectorTimeseries({metaverseId, sectorId});

  const mainContent = <EntityDetail
    feature={feature}
    state={state}
    timeseries={timeseries}
  />;

  return <Layout
    mainContent={mainContent}
    miniSidePanelSelectedActionId='sectorView'
  />;
};

export default SectorView;
