import React from 'react';
import {styled} from '@mui/material/styles';

import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

import Logo_geomatico from '../img/girona/Logo_geomatico.png';
import Logo_generalitat_catalunya from '../img/girona/Logo_generalitat_catalunya';
import Logo_diputacio_girona from '../img/girona/Logo_diputacio_girona';
import Logo_FEDER from '../img/girona/Logo_FEDER';
import Logo_Girona_aigua from '../img/girona/Logo_Girona_aigua.png';

const LogoDiputacioGirona = styled(Logo_diputacio_girona)({
  width: '130px',
});

const LogoGeneralitatCatalunya = styled(Logo_generalitat_catalunya)({
  width: '85px',
});
const LogoFEDER = styled(Logo_FEDER)({
  marginTop: '4px',
  marginBottom: '8px',
  width: '150px',
});

const SponsorsGirona = () => {
  return <Box sx={{display: 'flex', alignItems: 'flex-end', flexGrow: 2, padding: '12px'}}>
    <Box sx={{display: 'flex', justifyContent: 'space-around', alignItems: 'center', flexWrap: 'wrap'}}>
      <Link href='http://www.ddgi.cat/web/servei/3375/girona--regio-sensible-a-l-aigua-ambit-d-especialitzacio--aigua' target='_blank'>
        <img src={Logo_Girona_aigua} width='100%' alt="Girona, regió sensible a l'aigua"/>
      </Link>
      <LogoFEDER/>
      <LogoGeneralitatCatalunya/>
      <LogoDiputacioGirona/>
      <div style={{marginTop: 5}}>
        <Link href='https://geomatico.es' target='_blank'>
          <img src={Logo_geomatico} width={80} alt='geomatico.es'/>
        </Link>
      </div>
    </Box>
  </Box>;
};

export default SponsorsGirona;