import React, {FC} from 'react';

import Layout from '../components/Layout';
import WmsServiceDocumentation from '../components/geoservices/WmsServiceDocumentation';

const GeoservicesView: FC = () =>
  <Layout
    mainContent={<WmsServiceDocumentation/>}
    miniSidePanelSelectedActionId='geoservicesView'
  />;

export default GeoservicesView;
