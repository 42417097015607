import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
//hooks
import {useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

//MUI
import styled from '@mui/styles/styled';
import {useTheme} from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
//geocomponents
import ResponsiveHeader from '@geomatico/geocomponents/Layout/ResponsiveHeader';
import MiniSidePanel from '@geomatico/geocomponents/Layout/MiniSidePanel';
import SidePanel from '@geomatico/geocomponents/Layout/SidePanel';
import {DRAWER_WIDTH, MINI_SIDE_PANEL_DENSE_WIDTH, MINI_SIDE_PANEL_WIDTH, MINISIDEPANEL_CONFIG} from '../config';
import Logo from './icons/Logo';
import Quercus from './icons/Quercus';
import LanguagePicker from './LanguagePicker';
import useProfile from '../hooks/useProfile';

import LogoIrrilands from '../img/lleida/Logo_IrriLands.png';

const drawerWidth = DRAWER_WIDTH + 'px';

const headerSx = {
  '&.MuiAppBar-root': {zIndex: 1500},
  '& .ResponsiveHeader-logo': {marginLeft: 1},
  '& .ResponsiveHeader-title': {marginLeft: 12}
};

const Main = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'widescreen' && prop !== 'isLeftDrawerOpen'
})(({widescreen, isLeftDrawerOpen}) => ({
  flexGrow: 1,
  padding: 0,
  position: 'absolute',
  top: 56,
  '@media (min-width: 0px) and (orientation: landscape)': {
    top: 48
  },
  '@media (min-width: 600px)': {
    top: 64
  },
  bottom: 0,
  right: 0,
  left: widescreen ? (isLeftDrawerOpen && DRAWER_WIDTH) + MINI_SIDE_PANEL_WIDTH : MINI_SIDE_PANEL_DENSE_WIDTH
}));

const Layout = ({mainContent, sidePanelContent, miniSidePanelSelectedActionId}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const {i18n} = useTranslation();
  const {landparcel} = useParams();

  const miniSidePanelOptions = !landparcel
    ? MINISIDEPANEL_CONFIG.filter(el => el.id !== 'landparcelView')
    : MINISIDEPANEL_CONFIG;

  const widescreen = useMediaQuery(theme.breakpoints.up('sm'), {noSsr: true});
  const [isSidePanelOpen, setIsSidePanelOpen] = useState(true);

  const sidePanelSx = useMemo(() => ({
    '& .MuiPaper-root': {
      left: widescreen ? MINI_SIDE_PANEL_WIDTH : MINI_SIDE_PANEL_DENSE_WIDTH,
      padding: 0
    }
  }), [widescreen]);

  const handleActionClick = (id) => {
    const config_element = MINISIDEPANEL_CONFIG.find(el => el.id === id);
    if (miniSidePanelSelectedActionId === id && sidePanelContent) {
      setIsSidePanelOpen(!isSidePanelOpen);
    } else if (config_element.isNavigable) {
      navigate(config_element.route);
    }
  };

  const handleSidepanelClose = () => setIsSidePanelOpen(!isSidePanelOpen);

  const profile = useProfile();

  return (
    <>
      <ResponsiveHeader
        logo={profile?.profile === 'bardenas' ? <Quercus/> : <Logo/>}
        title={profile?.title[i18n.language.slice(0, 2)]}
        onStartIconClick={widescreen ? undefined : handleSidepanelClose}
        isStartIconCloseable={isSidePanelOpen}
        sx={headerSx}
      >
        {profile?.profile === 'lleida' ? <img src={LogoIrrilands} alt="Logo IrriLands" style={{height: '100%', backgroundColor: 'white', marginLeft: '30px', marginRight: '30px'}}/> : null}
        <LanguagePicker/>
      </ResponsiveHeader>

      <MiniSidePanel
        actions={miniSidePanelOptions}
        selectedActionId={miniSidePanelSelectedActionId}
        onActionClick={handleActionClick}
        dense={!widescreen}
      />
      {
        sidePanelContent && isSidePanelOpen && <SidePanel
          drawerWidth={drawerWidth}
          anchor="left"
          isOpen={isSidePanelOpen}
          onClose={handleSidepanelClose}
          widescreen={widescreen}
          sx={sidePanelSx}
        >
          {sidePanelContent}
        </SidePanel>
      }
      <Main widescreen={widescreen} isLeftDrawerOpen={!!(sidePanelContent && isSidePanelOpen)}>
        {mainContent}
      </Main>
    </>
  );
};

Layout.propTypes = {
  sidePanelContent: PropTypes.element,
  mainContent: PropTypes.element.isRequired,
  miniSidePanelSelectedActionId: PropTypes.string.isRequired,
};

export default Layout;
