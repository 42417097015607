import {Accessor, AccessorFunction, ChangeFlags, Color, CompositeLayer, Unit} from '@deck.gl/core/typed';
import {GeoJsonLayer, GeoJsonLayerProps, TextLayer} from '@deck.gl/layers/typed';
import getLabelAnchors from '../utils/mapData/getLabelAnchors';
import GeoJSON from 'geojson';

// Idea taken from: https://observablehq.com/@pessimistress/deck-gl-custom-layer-tutorial

const defaultProps= {
  // Inherit all of GeoJsonLayer's props
  ...GeoJsonLayer.defaultProps,
  // Label for each feature
  getLabel: {type: 'accessor', value: (x: { text: string }) => x.text},
  // Label size for each feature
  getLabelSize: {type: 'accessor', value: 32},
  // Label color for each feature
  getLabelColor: {type: 'accessor', value: [0, 0, 0, 255]},
  // Label always facing the camera
  billboard: true,
  // Label size units
  labelSizeUnits: 'pixels',
  // Label background color
  labelBackground: {type: 'color', value: null, optional: true},
  // Label font
  fontFamily: 'Monaco, monospace'
};

type LabeledGeoJsonLayerProps<DataT> = {
  getLabel: AccessorFunction<DataT, string>;
  getLabelSize: Accessor<DataT, number>;
  getLabelColor: Accessor<DataT, Color>;
  labelSizeUnits?: Unit;
  labelBackground?: Color;
  billboard?: boolean;
  fontFamily?: string;
} & GeoJsonLayerProps;

class LabeledGeoJsonLayer<DataT> extends CompositeLayer<LabeledGeoJsonLayerProps<DataT>> {
  updateState({changeFlags}: {changeFlags: ChangeFlags}) {
    const data = this.props.data as GeoJSON.FeatureCollection;
    if (changeFlags.dataChanged && data?.features) {
      const labelData = data.features
        .flatMap((feature: GeoJSON.Feature, index: number) => {
          const labelAnchors = getLabelAnchors(feature.geometry);
          return labelAnchors.map(p => this.getSubLayerRow({position: p}, feature, index));
        });

      this.setState({labelData});
    }

  }

  renderLayers() {
    const {
      getLabel,
      getLabelSize,
      getLabelColor,
      labelSizeUnits,
      labelBackground,
      billboard,
      fontFamily
    } = this.props;
    return [
      new GeoJsonLayer(this.props, this.getSubLayerProps({id: 'geojson'}), {
        data: this.props.data,
        updateTriggers: this.props.updateTriggers
      }),
      new TextLayer(this.getSubLayerProps({id: 'text'}), {
        data: this.state.labelData,
        fontFamily,
        billboard,
        sizeUnits: labelSizeUnits,
        getBackgroundColor: labelBackground,
        getPosition: d => d.position,
        characterSet: 'auto',
        getAngle: 0,
        getTextAnchor: 'middle',
        getAlignmentBaseline: 'center',
        // @ts-ignore por alguna razón no le gusta a typescript, pero funciona
        getText: this.getSubLayerAccessor(getLabel),
        getSize: this.getSubLayerAccessor(getLabelSize),
        getColor: this.getSubLayerAccessor(getLabelColor)
      })
    ];
  }
}

LabeledGeoJsonLayer.layerName = 'LabeledGeoJsonLayer';
LabeledGeoJsonLayer.defaultProps = defaultProps;

export default LabeledGeoJsonLayer;
