import {MapEntity, TileParams} from '../../types/commonTypes';
import {API_BASE_URL} from '../../config';
import {getSectorsByDistrict, groupByDistrict} from '../districtUtils';

export type MapTimeseries = {
  dates: Array<string>,
  data: Record<string, Array<number>>,
};

const normalizeBySumArea = (timeseries: MapTimeseries, sumareas: MapTimeseries): MapTimeseries => {
  return {
    ...timeseries,
    data: Object.entries(timeseries.data).reduce((accum, [sectorId, values]) => {
      accum[sectorId] = values.map((value: number, index: number) => Math.round( 100 * 1e6 * value / sumareas.data[sectorId][index]) / 100);
      return accum;
    }, {} as Record<string, Array<number>>)
  };
};

const loadLandparcelTimeseries = async (params: TileParams, year: number): Promise<MapTimeseries> => {
  const {metaverseId, variableConfig, tileIndex, signal} = params;
  if (tileIndex === undefined) {
    console.warn('loadLandparcelTimeseries was called without a tileindex. The API request has been blocked to avoid performance issues.');
    return Promise.resolve({
      dates: [],
      data: {}
    });
  } else {
    const tileIndexParam = `&tileindex=${tileIndex.z},${tileIndex.x},${tileIndex.y}`;
    const timeseriesUrl = `${API_BASE_URL}/metaverses/${metaverseId}/landparcels/timeseries/?year=${year}&variable=${variableConfig.apiFieldName}${tileIndexParam}`;
    const response = await fetch(timeseriesUrl, {signal});
    return await response.json();
  }

};

const loadDistrictOrSectorTimeseries = async (params: TileParams, year: number): Promise<MapTimeseries> => {
  const {metaverseId, mapEntity, variableConfig, signal} = params;
  const timeseriesUrls = [
    `${API_BASE_URL}/metaverses/${metaverseId}/districtsectors/timeseries/?year=${year}&variable=${variableConfig.apiFieldName}`,
    `${API_BASE_URL}/metaverses/${metaverseId}/districtsectors/timeseries/?year=${year}&variable=sum_area`
  ];
  const fetches = timeseriesUrls.map(url => fetch(url, {signal}).then(response => response.json()));

  const [variableTimeseries, sumareaTimeseries]: Array<MapTimeseries> = await Promise.all(fetches);

  if (mapEntity === MapEntity.DISTRICT) {
    const sectorsByDistrict = await getSectorsByDistrict();
    return normalizeBySumArea(
      groupByDistrict(variableTimeseries, sectorsByDistrict),
      groupByDistrict(sumareaTimeseries, sectorsByDistrict)
    );
  } else {
    return normalizeBySumArea(variableTimeseries, sumareaTimeseries);
  }
};

const loadTimeseries = async (params: TileParams, year: number): Promise<MapTimeseries> => {
  if (!params.variableConfig.hasRecord || params.variableConfig.isRaster) {
    // No timeseries to fetch, return an empty dataset
    return Promise.resolve({
      dates: [],
      data: {}
    });
  }
  if (params.mapEntity === MapEntity.LANDPARCEL) {
    return loadLandparcelTimeseries(params, year);
  } else {
    return loadDistrictOrSectorTimeseries(params, year);
  }
};

export default loadTimeseries;
