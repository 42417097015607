import React from 'react';

import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

import Logo_geomatico from '../img/girona/Logo_geomatico.png';
import Logo_AEI from '../img/lleida/Logo_AEI';
import Logo_FEADER from '../img/lleida/Logo_FEADER.png';
import Logo_generalitat_clima from '../img/lleida/Logo_generalitat_clima.png';
import Logo_xarxa_i_cat from '../img/lleida/Logo_xarxa_i_cat.jpg';
import Logo_Comreg4et from '../img/lleida/Logo_Comreg4et.png';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';

const SponsorsLleida = () => {

  const {t} = useTranslation();

  return <Box sx={{display: 'flex', alignItems: 'flex-end', flexGrow: 2, padding: '12px', }}>
    <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap'}}>
      <div>
        <Link href='https://www.aei.gob.es/' target='_blank' sx={{display: 'flex', flexWrap: 'nowrap', alignItems: 'center', p:1, bgcolor: '#f9f9f9', pr:1, mb:1}}>
          <Box sx={{bgcolor: '#f9f9f9', width: '75%'}}>
            <Typography sx={{fontSize: 10, width: '100%', display: 'inline-block', color: 'grey.800'}}>{t('sponsors.digispac')}</Typography>
            <Typography sx={{fontSize: 10, width: '100%', display: 'inline-block', color: 'grey.800'}}>{t('sponsors.digispacText')}</Typography>
          </Box>
          <Logo_AEI/>
        </Link>
      </div>
      
      <div style={{marginTop: 0}}>
        <Link href='https://comreg4et.org/es/' target='_blank'>
          <img src={Logo_Comreg4et} width={200} alt={t('sponsors.alt.Comreg4et')}/>
        </Link>
      </div>
      <div style={{marginTop: 0}}>
        <Link href='https://exteriors.gencat.cat/ca/ambits-dactuacio/afers_exteriors/ue/fons_europeus/fonsuecat/quins_fons/feader/' target='_blank'>
          <img src={Logo_FEADER} width={240} alt={t('sponsors.alt.FEADER')}/>
        </Link>
      </div>
      <div style={{marginTop: 0, width: '100%'}}>
        <Link href='https://agricultura.gencat.cat/ca/inici.es' target='_blank'>
          <img src={Logo_generalitat_clima} width={240} alt={t('sponsors.alt.FEADER')}/>
        </Link>
      </div>
      <div style={{marginTop: 0}}>
        <Link href='https://ruralcat.gencat.cat/web/guest/xarxa-i.cat' target='_blank'>
          <img src={Logo_xarxa_i_cat} width={130} alt="Xarxa d'Innovació agroalimentària i rural de Catalunya"/>
        </Link>
      </div>
      <div style={{marginTop: 5}}>
        <Link href='https://geomatico.es' target='_blank'>
          <img src={Logo_geomatico} width={80} alt='geomatico.es'/>
        </Link>
      </div>
    </Box>
  </Box>;
};

export default SponsorsLleida;