import React, {useState} from 'react';
import PropTypes from 'prop-types';

import Paper from '@mui/material/Paper';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import SelectInput from '@geomatico/geocomponents/Forms/SelectInput';

import WmsLayerPreview from './WmsLayerPreview';
import useProfile from '../../hooks/useProfile';

//STYLES
const cardHeaderStyle = {
  bgcolor: 'lightgrey',
  color: '#252424'
};

const width = 1000;
const height = 601;

const calculateBbox = (extent, width, height) => {
  if (!extent) {
    return [0, 0, 0, 0];
  } else {
    const center = [(extent[0] + extent[2]) / 2, (extent[1] + extent[3]) / 2];
    const latFactor = Math.cos(center[1] * (Math.PI / 180));

    const xPixelSize = (extent[2] - extent[0]) * latFactor / width;
    const yPixelSize = (extent[3] - extent[1]) / height;
    const pixelSize = Math.max(xPixelSize, yPixelSize);

    const xmin = center[0] - (width * pixelSize / (2 * latFactor));
    const ymin = center[1] - (height * pixelSize / 2);
    const xmax =  center[0] + (width * pixelSize / (2 * latFactor));
    const ymax = center[1] + (height * pixelSize / 2);

    return [xmin, ymin, xmax, ymax];
  }
};

const WmsLayerDocumentation = ({Name, Title, Style, Dimension}) => {
  const [style, setStyle] = useState('');
  const styles = Style.length ? Style : [Style];
  const styleOptions = styles.map(style => ({
    id: style.Name,
    label: style.Title
  }));

  const dimensions = Dimension ? (Dimension.length ? Dimension : [Dimension]) : [];
  const timeDimension = dimensions.find(d => d.name === 'time');

  const [time, setTime] = useState('');
  const times = timeDimension ? timeDimension['#text'].split(',') : [];
  const timeOptions = times.map(time => ({
    id: time,
    label: time
  }));

  const profile = useProfile();
  const bbox = calculateBbox(profile?.dataExtent, width, height);

  return <Paper sx={{width: 1500, mb: 2}} elevation={5}>
    <CardHeader
      title={
        <Typography variant='subtitle1' sx={{textTransform: 'uppercase', fontWeight: 'bold'}}>{Title}</Typography>}
      sx={cardHeaderStyle}
    />
    <Stack direction='row'>
      <Paper sx={{border: '1px solid lightgrey', p:1, m:2, borderRadius: 1, bgcolor: '#f5f5f5', minWidth: '425px', height: 'auto'}}>
        <Stack direction='row' sx={{alignItems: 'baseline'}}>
          <Typography component='p'> ? LAYERS = </Typography>
          <Typography component='p' sx={{ml:1, fontWeight: 'bold'}}>{Name}</Typography>
        </Stack>
        {
          styles.length > 1 && <Stack direction='row' sx={{alignItems: 'baseline'}}>
            <Typography component='code'> & STYLES = </Typography>
            <SelectInput
              options={styleOptions}
              selectedOptionId={style}
              onOptionChange={setStyle}
              placeholderLabel='default'
              allowEmptyValue
              sx={{ml:1}}
            />
          </Stack>
        }
        {
          times.length > 1 && <Stack direction='row' sx={{alignItems: 'baseline'}}>
            <Typography component='code'> & TIME = </Typography>
            <SelectInput
              options={timeOptions}
              selectedOptionId={time}
              onOptionChange={setTime}
              placeholderLabel='default'
              allowEmptyValue
              sx={{ml:1}}
            />
          </Stack>
        }
      </Paper>
      <Stack sx={{mt:2}}>
        <WmsLayerPreview width={width} height={height} layers={Name} styles={style} time={time} srs='EPSG:4326' bbox={bbox}/>
      </Stack>
    </Stack>
  </Paper>;
};

WmsLayerDocumentation.propTypes = {
  Name: PropTypes.string,
  Title: PropTypes.string,
  Style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  Dimension: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default WmsLayerDocumentation;
