import {API_BASE_URL} from '../../config';
import useFetch from '../useFetch';
import {ApiHook} from './types';

type Params = {
  metaverseId: number
}

export type Years = {
  available_years: Array<number>,
  current_year?: number
};

const useYears: ApiHook<Params, Years> = ({
  metaverseId
}) => {
  const URL = metaverseId !== undefined ?
    `${API_BASE_URL}/metaverses/${metaverseId}/years/` :
    undefined;
  const {data} = useFetch<Years>(URL);
  return data;
};

export default useYears;
