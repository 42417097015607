import {INITIAL_SELECTED_VARIABLE} from '../config';
import {useNavigate, useParams} from 'react-router-dom';
import {useCallback, useMemo} from 'react';

const useMapRoute = () => {
  const navigate = useNavigate();
  const {
    position,
    metaverseIdStr,
    yearStr,
    variable = INITIAL_SELECTED_VARIABLE,
    date
  } = useParams();

  const metaverseId = useMemo(() => metaverseIdStr === undefined ? undefined : ~~metaverseIdStr, [metaverseIdStr]);
  const year = useMemo(() => yearStr === undefined ? undefined : ~~yearStr, [yearStr]);

  const navigateTo = useCallback((position: string | undefined, metaverseId: number | undefined, variable: string, year: number | undefined, date: string | undefined) => {
    let route = '../map';
    route += `/${position}/variable/${variable}`;
    if (metaverseId) {
      route += `/metaverse/${metaverseId}`;
      if (year) {
        route += `/year/${year}`;
        if (date) {
          route += `/date/${date}`;
        }
      }
    }
    navigate(route, {replace: true});
  }, [navigate]);

  const setPosition = (newPosition: string) => navigateTo(newPosition, metaverseId, variable, year, date);
  const setMetaverseId = (newMetaverseId: number) => navigateTo(position, newMetaverseId, variable, year, date);
  const setVariable = (newVariable: string) => navigateTo(position, metaverseId, newVariable, year, date);
  const setYear = (newYear: number) => navigateTo(position, metaverseId, variable, newYear, undefined);
  const setDate = (newDate: string) => navigateTo(position, metaverseId, variable, year, newDate);

  return {
    position,
    metaverseId,
    year,
    variable,
    date,
    setPosition,
    setMetaverseId,
    setVariable,
    setYear,
    setDate
  };
};

export default useMapRoute;
