import React, {useEffect, useMemo, useRef} from 'react';
import PropTypes from 'prop-types';

import * as d3 from 'd3';
import Box from '@mui/material/Box';

import useColorRamp from '@geomatico/geocomponents/hooks/useColorRamp';
import Legend from '../d3/legend';
import grey from '@mui/material/colors/grey';

const ColorRampLegend = (props) => {
  const {domain, colorScheme, reverse, title, sx} = props;

  const ref = useRef();

  const {d3ScaleHex} = useMemo(() => useColorRamp(colorScheme, domain, reverse), [colorScheme, domain, reverse]);

  const key = useMemo(() => JSON.stringify(props), [props]);

  const sx2 = useMemo(() => ({
    width: 500,
    height: 56,
    padding: '0 15px',
    border: `1px solid ${grey[300]}`,
    backgroundColor: 'rgba(255, 255, 255, 0.67)',
    borderRadius: '2px',
    ...sx
  }), [sx]);

  useEffect(() => {
    Legend(d3.select(ref.current), d3ScaleHex,
      {
        title,
        width: sx2.width
      }
    );
  }, [domain, colorScheme, reverse, title, sx]);

  return <Box sx={sx2}>
    <svg key={key} ref={ref}/>
  </Box>;
};

ColorRampLegend.propTypes = {
  domain: PropTypes.arrayOf(PropTypes.number),
  colorScheme: PropTypes.string,
  reverse: PropTypes.bool,
  title: PropTypes.string,
  sx: PropTypes.object
};

ColorRampLegend.defaultProps = {
  domain: [0, 1],
  reverse: false
};

export default ColorRampLegend;
