import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import {SECTOR_VARIABLES} from '../config';
import Chart from './Chart';
import SubsectionTitle from './SubsectionTitle';
import MainVariablesTable from './MainVariablesTable';
import MiniMap from './MiniMap';
import {DistrictFeature, SectorFeature} from '../hooks/geoserver/types';
import {SectorTimeseries} from '../hooks/api/useSectorTimeseries';
import {DistrictState} from '../hooks/api/useDistrictState';
import {SectorState} from '../hooks/api/useSectorState';
import {DistrictTimeseries} from '../hooks/api/useDistrictTimeseries';

const formatDateOptions: Intl.DateTimeFormatOptions = {year: 'numeric', month: 'short', day: 'numeric'};

export interface EntityDetailProps {
  feature?: DistrictFeature | SectorFeature,
  state?: DistrictState | SectorState,
  timeseries?: DistrictTimeseries | SectorTimeseries
}

const EntityDetail: FC<EntityDetailProps> = ({feature, state, timeseries}) => {
  const {i18n, t} = useTranslation();
  const [showAllCharts, setShowAllCharts] = useState(false);

  const recordVariables = Object.entries(SECTOR_VARIABLES).map(([key, value]) => ({id: key, ...value})).filter(variable => variable.hasRecord);
  const mainChartVariables = recordVariables.filter(v => v.hasForecast);
  const secondaryChartVariables = recordVariables.filter(v => !v.hasForecast);

  const toggleShowAllCharts = () => setShowAllCharts(!showAllCharts);

  const formatLocaleDate = (date: string) => new Date(date).toLocaleDateString(i18n.language, formatDateOptions);

  return (
    <Box sx={{height: '100%', maxWidth: '1200px'}}>
      <Grid container spacing={2} sx={{p: 5}}>
        <Grid item xs={12} md={12}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{background: 'lightgrey', height: '256px'}}
          >
            {feature ?
              <MiniMap feature={feature}/> :
              <Skeleton variant="rectangular" animation="wave" sx={{bgcolor: 'action.hover'}}/>
            }
          </Box>
        </Grid>


        <Grid item md={12} sx={{mt: 3}}>
          <Typography
            variant='h5'
            sx={{fontWeight: 'bold', whiteSpace: 'nowrap', mr: 3}}
          >
            {`${t('actualState')}${state ? ` (${formatLocaleDate(state.day)})` : ''}`}
          </Typography>
          <Divider/>
        </Grid>

        <Grid item xs={12} md={12} sx={{mt: 3}}>
          {state
            ? <MainVariablesTable state={state} variables={SECTOR_VARIABLES}/>
            : <Skeleton variant="rectangular" height={298} animation="wave" sx={{bgcolor: 'action.hover'}}/>
          }
        </Grid>

        {
          mainChartVariables.map(variable =>
            <Grid key={variable.id} item xs={12} md={6} sx={{height: '300px', mt: 5}}>
              <SubsectionTitle titleKey={`variable.${variable.id}`}/>
              {timeseries === undefined
                ? <Skeleton variant="rectangular" animation="wave" height={'100%'} sx={{bgcolor: 'action.hover'}}/>
                : <Chart variable={variable.id} units={variable.units} data={timeseries && timeseries[variable.apiFieldName as keyof SectorTimeseries]}/>
              }
            </Grid>
          )
        }

        {
          showAllCharts &&
          secondaryChartVariables.map(variable =>
            <Grid key={variable.id} item xs={12} md={6} sx={{height: '300px', mt: 5}}>
              <SubsectionTitle titleKey={`variable.${variable.id}`}/>
              {timeseries === undefined
                ? <Skeleton variant="rectangular" animation="wave" height={'100%'} sx={{bgcolor: 'action.hover'}}/>
                : <Chart variable={variable.id} units={variable.units} data={timeseries && timeseries[variable.apiFieldName as keyof SectorTimeseries]}/>
              }
            </Grid>
          )
        }

        {
          !showAllCharts &&
          <Grid item xs={12} sx={{mt: 9, textAlign: 'center'}}>
            <Button
              disabled={!timeseries}
              onClick={toggleShowAllCharts}
              variant="contained">
              {t('showAllCharts')}
            </Button>
          </Grid>
        }
      </Grid>
    </Box>
  );
};

export default EntityDetail;
