import { lighten } from '@mui/material/styles';
import createTheme from '@mui/material/styles/createTheme';

const theme = (primaryColor: string, secondaryColor: string) => createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    text: {
      primary: '#000000',
      secondary: '#746F81',
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {

        '*::-webkit-scrollbar': {
          width: '0.2em',
          height: '0.2em'
        },
        '*::-webkit-scrollbar-track': {
          WebkitBoxShadow: 'inset 0 0 2px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
          backgroundColor: lighten(primaryColor, 0.5),
          outline: '0px solid slategrey'
        }
      }
    }
  },
  zIndex: {
    modal: 1500
  }
});

export default theme;
