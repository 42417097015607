import {ApiHook, StateVariable} from '../api/types';
import {SectorState} from '../api/useSectorState';
import {useCallback, useEffect, useState} from 'react';
import loadState, {MapState} from '../../utils/mapData/loadState';
import {MapEntity} from '../../types/commonTypes';

type Params = {
  metaverseId: number,
  districtId: number
}
export type DistrictState = SectorState;

const useDistrictState: ApiHook<Params, DistrictState> = ({metaverseId, districtId}): DistrictState | undefined => {
  const [districtStates, setDistrictStates] = useState<MapState>();

  useEffect(() => {
    if (metaverseId === undefined) {
      setDistrictStates(undefined);
    } else {
      loadState({
        metaverseId,
        mapEntity: MapEntity.DISTRICT
      }, true).then(setDistrictStates);
    }
  }, [metaverseId]);

  const valueOf = useCallback((variable: StateVariable) => {
    if (districtStates && districtId !== undefined) {
      const index = districtStates.variables.indexOf(variable);
      const value = districtStates.data[districtId][index];

      const sumAreaIndex = districtStates.variables.indexOf('sum_area');
      const area = districtStates.data[districtId][sumAreaIndex];

      return index === sumAreaIndex ? value : Math.round( 100 * value * area / 1e6) / 100; // Convert back from m³/ha to hm³
    } else {
      return NaN;
    }
  }, [districtStates, districtId]);

  if (districtStates && districtId !== undefined) {
    return {
      day: districtStates.date,
      accum_et: valueOf('accum_et'),
      accum_irr: valueOf('accum_irr'),
      accum_watercatch: valueOf('accum_watercatch'),
      et: valueOf('et'),
      irr: valueOf('irr'),
      watercatch: valueOf('watercatch'),
      p75_pending_et: valueOf('p75_pending_et'),
      p75_pending_irr: valueOf('p75_pending_irr'),
      p75_pending_watercatch: valueOf('p75_pending_watercatch'),
      sum_area: valueOf('sum_area')
    };
  }
};

export default useDistrictState;
