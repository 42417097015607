export interface Variable {
  apiFieldName: string,
  domain: [number, number],
  colorScheme: string,
  units: string,
  mapUnits?: string,
  isMain: boolean,
  hasRecord: boolean,
  hasState: boolean,
  hasForecast: boolean,
  reverseColor?: boolean,
  isRaster?: boolean,
}

export enum MapEntity {
  LANDPARCEL = 'landparcel',
  DISTRICT = 'district',
  SECTOR = 'sector'
}

export type TileIndex = {
  z: number,
  x: number,
  y: number
};

export type TileParams = {
  metaverseId: number,
  mapEntity: MapEntity,
  variableConfig: Variable,
  tileIndex?: TileIndex,
  signal?: AbortSignal
}
