import React, {FC, ReactNode, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PauseIcon from '@mui/icons-material/Pause';
import PlayIcon from '@mui/icons-material/PlayArrow';
import NextIcon from '@mui/icons-material/NavigateNext';
import PrevIcon from '@mui/icons-material/NavigateBefore';
import Slider from '@mui/material/Slider';
import Stack from '@mui/material/Stack';
import styled from '@mui/styles/styled';

import BaseMapList from '@geomatico/geocomponents/Map/BaseMapList';
import SelectInput from '@geomatico/geocomponents/Forms/SelectInput';

import {LANDPARCEL_VARIABLES, MAPSTYLES, RASTER_VARIABLES, SECTOR_VARIABLES} from '../../config';

import SubsectionTitle from '../../components/SubsectionTitle';
import SponsorsGirona from '../../components/SponsorsGirona';
import VariablePicker from '../../components/VariablePicker';
import FetchStatusWrapper from '../../components/FetchStatusWrapper';
import {Metaverses} from '../../hooks/api/useMetaverses';
import {MapEntity, Variable} from '../../types/commonTypes';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import {getWmsLayers} from '../../utils/wmsLayers';
import useProfile from '../../hooks/useProfile';
import SponsorsLleida from '../../components/SponsorsLleida';
import SponsorsBardenas from '../../components/SponsorsBardenas';

const ScrollableContent = styled(Box)({
  overflow: 'auto',
  padding: '12px',
});

const baseMapListStyle = {
  '& .BaseMapList-item': {
    margin: 0
  }
};

interface SidePanelContentProps {
  metaverses?: Metaverses,
  metaverseId?: number,
  onMetaverseIdChanged: (metaverseId?: number) => void,
  years?: Array<number>,
  currentYear?: number,
  year?: number,
  onYearChanged: (year: number, current: boolean) => void,
  mapEntity: MapEntity,
  variable: string,
  onVariableChanged: (variable?: string) => void,
  dates?: Array<string>,
  currentDate?: string,
  date?: string,
  onDateChanged: (date: string) => void,
  mapStyle: string,
  onMapStyleChanged: (mapStyle: string) => void
}

const SidePanelContent: FC<SidePanelContentProps> = (props) => {
  const {
    metaverses,
    metaverseId,
    onMetaverseIdChanged,

    years,
    currentYear,
    year,
    onYearChanged,

    mapEntity,

    variable,
    onVariableChanged,

    dates,
    currentDate,
    date,
    onDateChanged,

    mapStyle,
    onMapStyleChanged
  } = props;

  const profile = useProfile();

  const {t, i18n} = useTranslation();

  const [existingRasterLayers, setExistingRasterVariables] = useState<{[index: string]: Variable}>();
  useEffect(() => {
    getWmsLayers().then(layers => {
      const geoserverLayerNames = layers.map(layer => layer.Name);
      const existingRasterLayers = Object.fromEntries(Object.entries(RASTER_VARIABLES)
        .filter(([, value]) => geoserverLayerNames.includes(value.apiFieldName))
      );
      setExistingRasterVariables(existingRasterLayers);
    });
  }, []);

  const variables = {...(mapEntity === MapEntity.LANDPARCEL ? LANDPARCEL_VARIABLES : SECTOR_VARIABLES), ...existingRasterLayers};

  const isCurrentYear = currentYear && year ? currentYear === year : false;
  const isCurrentDate = currentDate && date ? currentDate === date : false;
  const variableConfig = variables[variable];

  const metaverseSelectOptions = metaverses ? metaverses
    .map(({id, name}) => ({
      id: id.toString(),
      label: name
    })) : [];
  const metaverseSelectValue = metaverseId?.toString() || '';
  const handleMetaverseChange = (ds?: string) => ds !== undefined && onMetaverseIdChanged(~~ds);

  const yearSelectOptions = years ? years
    .map(yr => ({
      id: yr.toString(),
      label: yr === currentYear ? `${yr} (${t('currentYear')})` : yr.toString()
    })) : [];
  const yearSelectValue = year?.toString() || '';
  const handleYearChange = (yr?: string) => yr !== undefined && onYearChanged(parseInt(yr), parseInt(yr) === currentYear);

  const variableSelectOptions = Object.entries(variables)
    .filter(([, v]) => !v.isMain && (isCurrentYear || v.hasRecord))
    .map(([k,]) => ({id: k, label: t(`variable.${k}`)}));

  const variableSelectValue = variableSelectOptions.find(({id}) => id === variable) ? variable : '';
  const handleVariableChange = (variable?: string) => onVariableChanged(variable);

  const mapStyles = MAPSTYLES.map(mapStyle => ({
    ...mapStyle,
    label: t(`mapStyle.${mapStyle.id}`)
  }));

  const dateSliderValues = dates ? dates.map((date, i) => ({
    value: i,
    date: date
  })) : [];
  const dateSliderValue = dateSliderValues.find(m => m.date === date)?.value;
  const dateLabel = date && new Date(date).toLocaleDateString(i18n.resolvedLanguage, {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });

  const handleDateChange = (e: Event, v: number | Array<number>) => {
    const element = dateSliderValues.find(m => m.value === v);
    if(element) onDateChanged(element.date);
  };

  const [isAnimating, setAnimating] = useState(false);

  const setNextDate = () => {
    if (dates && dates.length) {
      const nextIndex = (dates.indexOf(date || '') + 1) % dates.length;
      onDateChanged(dates[nextIndex]);
    }
  };

  const setPrevDate = () => {
    if (dates && dates.length) {
      const nextIndex = (dates.indexOf(date || '') - 1) % dates.length;
      onDateChanged(dates[nextIndex]);
    }
  };

  useEffect(() => {
    if (isAnimating) requestAnimationFrame(setNextDate);
  }, [isAnimating]);

  useEffect(() => {
    if (isAnimating) requestAnimationFrame(setNextDate);
  }, [date]);

  useEffect(() => {
    setAnimating(false);
  }, [metaverseId, year, variable]);

  const toggleAnimating = () => setAnimating(!isAnimating);

  // TODO: Rethink a better solution based on profile file
  const sponsors: {[key: string]: ReactNode} = {
    'lleida': <SponsorsLleida/>,
    'ter': <SponsorsGirona/>,
    'bardenas': <SponsorsBardenas/>,
    'default': <SponsorsGirona/>  
  };

  return <Stack sx={{height: '100%'}}>
    <ScrollableContent>
      <SubsectionTitle titleKey="sidePanelSection.metaverse"/>
      <FetchStatusWrapper
        isWaiting={metaverses === undefined}
        isEmpty={!metaverseSelectOptions.length || !metaverseSelectValue}
        errorText={t('error.metaverses')} emptyText={t('empty.metaverses')} height={48}
      >
        <SelectInput options={metaverseSelectOptions} selectedOptionId={metaverseSelectValue} onOptionChange={handleMetaverseChange}/>
      </FetchStatusWrapper>

      <SubsectionTitle titleKey="sidePanelSection.mapEntity"/>
      <Typography>{t(`mapEntity.${mapEntity}`)}</Typography>

      <SubsectionTitle titleKey="sidePanelSection.year"/>
      <FetchStatusWrapper
        isWaiting={years === undefined}
        isEmpty={!yearSelectOptions.length || !yearSelectValue}
        errorText={t('error.years')} emptyText={t('empty.years')} height={48}
      >
        {mapEntity === MapEntity.LANDPARCEL ?
          <SelectInput options={yearSelectOptions} selectedOptionId={yearSelectValue} onOptionChange={handleYearChange}/> :
          <Typography>--</Typography>
        }
      </FetchStatusWrapper>

      <SubsectionTitle titleKey="sidePanelSection.variable"/>
      <VariablePicker mapEntity={mapEntity} selectedVariable={variable} onSelectedVariableChange={handleVariableChange} isCurrentDate={isCurrentDate}/>
      {variableSelectOptions.length ? <SelectInput
        options={variableSelectOptions}
        selectedOptionId={variableSelectValue}
        onOptionChange={handleVariableChange}
        allowEmptyValue
        placeholderLabel={t('otherVariables') + '...'}
      />: null}

      <SubsectionTitle titleKey='sidePanelSection.date'/>
      <Box>
        <Grid container justifyContent="center">
          <Grid item>
            {dateLabel}
          </Grid>
          {variableConfig?.hasRecord &&
            <FetchStatusWrapper
              isWaiting={!dates || !date}
              isEmpty={dates?.length === 0 || date === undefined || dateSliderValue === undefined}
              errorText={t('error.dates')} emptyText={t('empty.dates')} height={70}
            >
              <Grid container justifyContent='center' item style={{width: '100%'}}>
                <Slider
                  style={{margin: '2px 16px'}}
                  marks={dateSliderValues}
                  min={0}
                  max={dateSliderValues.length - 1}
                  value={dateSliderValue}
                  onChange={handleDateChange}
                />
                <Grid item>
                  <IconButton color="primary" onClick={setPrevDate}><PrevIcon/></IconButton>
                  {variableConfig.isRaster ? null : isAnimating
                    ? <IconButton color="primary" onClick={toggleAnimating}><PauseIcon/></IconButton>
                    : <IconButton color="primary" onClick={toggleAnimating}><PlayIcon/></IconButton>
                  }
                  <IconButton color="primary" onClick={setNextDate}><NextIcon/></IconButton>
                </Grid>
              </Grid>
            </FetchStatusWrapper>
          }
        </Grid>
      </Box>

      <SubsectionTitle titleKey="sidePanelSection.baseMapStyle"/>
      <BaseMapList
        variant="pad"
        styles={mapStyles}
        selectedStyleId={mapStyle}
        onStyleChange={onMapStyleChanged}
        thumbnailWidth={36}
        thumbnailHeight={36}
        sx={baseMapListStyle}
      />

    </ScrollableContent>
    {
      profile && profile.profile in sponsors ?
        sponsors[profile.profile] :
        sponsors.default
    }
  </Stack>;
};

export default SidePanelContent;
