import React, {useEffect, useMemo, useState} from 'react';

import {INITIAL_MAPSTYLE_URL, LANDPARCEL_VARIABLES, RASTER_VARIABLES, SECTOR_VARIABLES} from '../config';

import SidePanelContent from './MapView/SidePanelContent';
import MainContent from './MapView/MainContent';
import Layout from '../components/Layout';
import useMapData from '../hooks/useMapData';
import useMapRoute from '../hooks/useMapRoute';
import {MapEntity} from '../types/commonTypes';
import useProfile from '../hooks/useProfile';
import usePosition from '../hooks/usePosition';
import {useTranslation} from 'react-i18next';

const MapView = () => {

  const {i18n: {resolvedLanguage}} = useTranslation();

  const {
    position,
    metaverseId,
    variable,
    year,
    date,
    setPosition,
    setMetaverseId,
    setVariable,
    setYear,
    setDate
  } = useMapRoute();

  const {
    metaverses,
    years,
    dates,
    metaverseId: computedMetaverseId,
    mapEntity,
    variable: computedVariable,
    year: computedYear,
    date: computedDate,
    currentYear,
    currentDate
  } = useMapData({position, metaverseId, variable, year, date});

  const profile = useProfile();
  const {position: initialPosition, setPosition: setInitialPosition, setViewport: setInitialViewport} = usePosition();

  useEffect(() => {
    if(!initialPosition) {
      if (position !== undefined && position !== 'undefined') {
        setInitialPosition(position);
      } else if(profile?.initialMapPosition) {
        setInitialViewport(profile.initialMapPosition);
      }
    }
  }, [initialPosition, position, profile?.initialMapPosition]);

  useEffect(() => {
    !!initialPosition && setPosition(initialPosition);
  }, [initialPosition]);

  useEffect(() => {
    if (computedMetaverseId && metaverseId !== computedMetaverseId) setMetaverseId(computedMetaverseId);
  }, [computedMetaverseId, metaverseId]);

  useEffect(() => {
    if (computedVariable && variable !== computedVariable) setVariable(computedVariable);
  }, [computedVariable, variable]);

  useEffect(() => {
    if (computedYear && year !== computedYear) setYear(computedYear);
  }, [computedYear, year]);

  useEffect(() => {
    if (year && computedDate && date !== computedDate) setDate(computedDate);
  }, [computedDate, date, year]);

  //const initialPosition = useMemo(() => position, [position]);
  const [mapStyle, setMapStyle] = useState(INITIAL_MAPSTYLE_URL);

  const variableConfig = useMemo(() =>
    RASTER_VARIABLES[variable] ?? (mapEntity === MapEntity.LANDPARCEL ?
      LANDPARCEL_VARIABLES[variable] :
      SECTOR_VARIABLES[variable]) // for DISTRICTS, use same SECTOR_VARIABLES
  , [variable, mapEntity]);

  const sidePanelContent = useMemo(() => <SidePanelContent
    metaverses={metaverses}
    metaverseId={metaverseId}
    onMetaverseIdChanged={(newMetaverseId?: number) => newMetaverseId !== undefined && setMetaverseId(newMetaverseId)}

    years={years}
    currentYear={currentYear}
    year={year}
    onYearChanged={setYear}

    mapEntity={mapEntity}

    variable={variable}
    onVariableChanged={newVariable => newVariable !== undefined && setVariable(newVariable)}

    dates={dates}
    currentDate={currentDate}
    date={date}
    onDateChanged={setDate}

    mapStyle={mapStyle}
    onMapStyleChanged={setMapStyle}
  />, [resolvedLanguage, metaverses, metaverseId, years, currentYear, year, mapEntity, variable, dates, currentDate, date, mapStyle]);

  const mainContent = useMemo(() => initialPosition ? <MainContent
    initialPosition={initialPosition}
    onPositionChanged={setPosition}
    metaverseId={metaverseId}
    year={year}
    currentYear={currentYear}
    mapEntity={mapEntity}
    variable={variable}
    variableConfig={variableConfig}
    mapStyle={mapStyle}
    date={date}
  /> : <></>, [resolvedLanguage, initialPosition, metaverseId, year, currentYear, mapEntity, variable, variableConfig, mapStyle, date]);

  return <Layout
    sidePanelContent={sidePanelContent}
    mainContent={mainContent}
    miniSidePanelSelectedActionId='mapView'
  />;
};

export default MapView;
