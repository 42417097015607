import React, {FC, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import {LANDPARCEL_VARIABLES} from '../config';
import Chart from './Chart';
import SubsectionTitle from './SubsectionTitle';
import YearSlider from './YearSlider';
import MainVariablesTable from './MainVariablesTable';
import OtherVariablesTable from './OtherVariablesTable';
import MiniMap from './MiniMap';
import {LandparcelVersionGrid} from './LandparcelVersionGrid';
import {LandparcelFeature} from '../hooks/geoserver/types';
import {Years} from '../hooks/api/useYears';
import {LandparcelVersion} from '../hooks/api/useLandparcelVersion';
import {LandparcelTimeseries} from '../hooks/api/useLandparcelTimeseries';
import {LandparcelState} from '../hooks/api/useLandparcelState';

const formatDateOptions: Intl.DateTimeFormatOptions = {year: 'numeric', month: 'short', day: 'numeric'};

export interface LandparcelDetailProps {
  feature?: LandparcelFeature,
  years?: Years,
  onYearChange: (newYear: number | Array<number>) => void,
  year: number,
  version?: LandparcelVersion,
  state?: LandparcelState,
  timeseries?: LandparcelTimeseries
}

const LandparcelDetail: FC<LandparcelDetailProps> = ({
  feature, years, onYearChange, year, version, state, timeseries
}) => {
  const {i18n, t} = useTranslation();
  const [showAllCharts, setShowAllCharts] = useState(false);

  const recordVariables = Object.entries(LANDPARCEL_VARIABLES).map(([key, value]) => ({id: key, ...value})).filter(variable => variable.hasRecord);
  const mainChartVariables = recordVariables.filter(v => v.hasForecast);
  const secondaryChartVariables = recordVariables.filter(v => !v.hasForecast);

  const toggleShowAllCharts = () => setShowAllCharts(!showAllCharts);
  const handleYearChange = (newYear: number | Array<number>) => onYearChange && onYearChange(newYear);

  const formatLocaleDate = (date: string ) => {
    return new Date(date).toLocaleDateString(i18n.resolvedLanguage, formatDateOptions);
  };

  return (
    <Box sx={{height: '100%', maxWidth: '1200px'}}>
      <Grid container spacing={2} sx={{p: 5}}>
        <Grid item xs={12} md={12}>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{background: 'lightgrey', height: '256px'}}
          >
            {feature ?
              <MiniMap feature={feature}/> :
              <Skeleton variant="rectangular" animation="wave" sx={{bgcolor: 'action.hover'}}/>
            }
          </Box>
        </Grid>

        <Grid item md={12} sx={{mt: 3}}>
          <Typography
            variant='h5'
            sx={{fontWeight: 'bold', whiteSpace: 'nowrap', mr: 3}}
          >
            {`${t('actualState')}${state ? ` (${formatLocaleDate(state.day)})` : ''}`}
          </Typography>
          <Divider/>
        </Grid>

        <Grid item xs={12} md={6} sx={{mt: 3}}>
          {state
            ? <MainVariablesTable state={state} variables={LANDPARCEL_VARIABLES}/>
            : <Skeleton variant="rectangular" height={298} animation="wave" sx={{bgcolor: 'action.hover'}}/>
          }
        </Grid>

        <Grid item xs={12} md={6} sx={{mt: 3}}>
          {state ?
            <OtherVariablesTable state={state} variables={LANDPARCEL_VARIABLES}/> :
            <Skeleton variant="rectangular" height={298} animation="wave" sx={{bgcolor: 'action.hover'}}/>
          }
        </Grid>

        <Grid item xs={12} md={12}>
          <Box mt={5} mb={1}>
            <Typography
              variant='h5'
              sx={{fontWeight: 'bold', whiteSpace: 'nowrap', mr: 3}}
            >
              {`${t('yearData')} ${year}`}
            </Typography>
            <Divider/>
          </Box>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            {
              years &&
              <Grid item sx={{width: '280px', height: '57px', mt: 5, ml: 1}}>
                <YearSlider
                  years={years.available_years}
                  selectedYear={year}
                  onYearChange={handleYearChange}
                />
              </Grid>
            }
          </Grid>
        </Grid>

        <LandparcelVersionGrid landparcelVersion={version}/>

        {
          mainChartVariables.map(variable =>
            <Grid key={variable.id} item xs={12} md={6} sx={{height: '300px', mt: 5}}>
              <SubsectionTitle titleKey={`variable.${variable.id}`}/>
              {timeseries === undefined
                ? <Skeleton variant="rectangular" animation="wave" height={'100%'} sx={{bgcolor: 'action.hover'}}/>
                : <Chart variable={variable.id} units={variable.units} data={timeseries && timeseries[variable.apiFieldName as keyof LandparcelTimeseries]}/>
              }
            </Grid>
          )
        }

        {
          showAllCharts &&
          secondaryChartVariables.map(variable =>
            <Grid key={variable.id} item xs={12} md={6} sx={{height: '300px', mt: 5}}>
              <SubsectionTitle titleKey={`variable.${variable.id}`}/>
              {timeseries === undefined
                ? <Skeleton variant="rectangular" animation="wave" height={'100%'} sx={{bgcolor: 'action.hover'}}/>
                : <Chart variable={variable.id} units={variable.units} data={timeseries && timeseries[variable.apiFieldName as keyof LandparcelTimeseries]}/>
              }
            </Grid>
          )
        }

        {
          !showAllCharts &&
          <Grid item xs={12} sx={{mt: 9, textAlign: 'center'}}>
            <Button
              disabled={!timeseries}
              onClick={toggleShowAllCharts}
              variant="contained">
              {t('showAllCharts')}
            </Button>
          </Grid>
        }
      </Grid>
    </Box>

  );
};

export default LandparcelDetail;
