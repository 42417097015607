import GeoJSON from 'geojson';
import {TileIndex} from '../../types/commonTypes';
import {MapTimeseries} from './loadTimeseries';
import {MapState} from './loadState';

export type IrriterFeature = GeoJSON.Feature<GeoJSON.Geometry, {
  tileIndex?: {z: number, x: number, y: number},
  timeseries: Record<string, number>,
  state: Record<string, number>
}>;

const buildIrriterFeatures = (features: Array<GeoJSON.Feature>, timeseries: MapTimeseries, state: MapState, tileIndex?: TileIndex): Array<IrriterFeature> => {
  return features.map((feature: GeoJSON.Feature) => {
    return {
      ...feature,
      properties: {
        ...feature.properties,
        tileIndex,
        timeseries: timeseries.data[feature.properties?.id as number]?.reduce((obj: Record<string, number>, value: number, i: number) => {
          obj[timeseries.dates[i]]=value;
          return obj;
        }, {} as Record<string, number>),
        state: state.data[feature.properties?.id as number]?.reduce((obj: Record<string, number>, value: number, i: number) => {
          obj[state.variables[i]]=value;
          return obj;
        }, {} as Record<string, number>)
      }
    };
  });
};

export default buildIrriterFeatures;
