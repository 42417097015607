import {API_BASE_URL} from '../../config';
import useFetch from '../useFetch';
import {ApiHook, SectorTimeseriesVariable, ChartTimeseries} from './types';

type Params = {
  metaverseId: number,
  sectorId: number
}

export type SectorTimeseries = Record<SectorTimeseriesVariable, ChartTimeseries>;

const useSectorTimeseries: ApiHook<Params, SectorTimeseries> = ({
  metaverseId,
  sectorId
}) => {
  const URL = metaverseId !== undefined && sectorId !== undefined ?
    `${API_BASE_URL}/metaverses/${metaverseId}/districtsectors/${sectorId}/timeseries/` :
    undefined;
  const {data} = useFetch<SectorTimeseries>(URL);
  return data;
};

export default useSectorTimeseries;
