import {GEOSERVER_WFS_URL} from '../../config';
import useFetch from '../useFetch';
import {ApiHook} from '../api/types';
import {FeatureCollection, LandparcelFeature} from './types';
import {MapEntity} from '../../types/commonTypes';

type Params = {
  landparcelId: number
}

const useLandparcelFeature: ApiHook<Params, LandparcelFeature> = ({
  landparcelId
}) => {
  const URL = landparcelId !== undefined ?
    `${GEOSERVER_WFS_URL(MapEntity.LANDPARCEL)}&srsName=EPSG:4326&featureID=${landparcelId}` :
    undefined;
  const {data} = useFetch<FeatureCollection<LandparcelFeature>>(URL);
  return data?.features?.length ? data.features[0] : undefined;
};

export default useLandparcelFeature;
