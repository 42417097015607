import {MapEntity, TileParams} from '../../types/commonTypes';
import {API_BASE_URL} from '../../config';
import {StateVariable} from '../../hooks/api/types';
import {getSectorsByDistrict, groupByDistrict} from '../districtUtils';

export type MapState = {
  date: string,
  variables: Array<StateVariable>
  data: Record<string, Array<number>>,
};

// Normalize values by area & convert units from hm3 to m3 (factor 1e6)
const normalizeBySumArea = (mapState: MapState): MapState => {
  const sumAreaIndex = mapState.variables.indexOf('sum_area');
  return (sumAreaIndex >= 0) ?
    {
      ...mapState,
      data: Object.entries(mapState.data).reduce((accum, [sectorId, values]) => {
        accum[sectorId] = values.map((value: number, index: number) => index === sumAreaIndex ? value : Math.round( 100 * 1e6 * value / mapState.data[sectorId][sumAreaIndex]) / 100);
        return accum;
      }, {} as Record<string, Array<number>>)
    } :
    mapState;
};

const loadState = async (params: Omit<TileParams, 'variableConfig'>, load: boolean): Promise<MapState> => {
  if (!load) {
    // No state to fetch, return an empty dataset
    return Promise.resolve({
      date: '',
      variables: [],
      data: {}
    });
  }

  const {metaverseId, mapEntity, tileIndex, signal} = params;
  const entityPath = mapEntity === MapEntity.LANDPARCEL ? 'landparcels' : 'districtsectors';
  const tileIndexParam = mapEntity === MapEntity.LANDPARCEL && tileIndex !== undefined ? `?tileindex=${tileIndex.z},${tileIndex.x},${tileIndex.y}` : '';
  const stateUrl = `${API_BASE_URL}/metaverses/${metaverseId}/${entityPath}/state/${tileIndexParam}`;
  const response = await fetch(stateUrl, {signal});
  const mapState: MapState = await response.json();

  if (mapEntity === MapEntity.SECTOR) {
    return normalizeBySumArea(mapState);
  } else if (mapEntity === MapEntity.DISTRICT) {
    const sectorsByDistrict = await getSectorsByDistrict();
    return normalizeBySumArea(groupByDistrict(mapState, sectorsByDistrict));
  } else {
    return mapState;
  }
};

export default loadState;
