import ReactDOM from 'react-dom';
import React from 'react';
//import * as Sentry from '@sentry/react';
//import {Integrations} from '@sentry/tracing';
import Routes from './routes/routes';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import {StyledEngineProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './theme';
import './i18n';
import useProfile from './hooks/useProfile';

/*if (process.env.NODE_ENV === 'production' && process.env.SENTRY_DSN) {
  const NO_PERFORMANCE = 0;
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: NO_PERFORMANCE,
  });
}*/

const App = () => {
  const profile = useProfile();
  return profile ? <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme(profile.primaryColor, profile.secondaryColor)}>
      <CssBaseline/>
      <Routes/>
    </ThemeProvider>
  </StyledEngineProvider> : <>...</>;
};

const target = document.getElementById('app');
if (target) ReactDOM.render(<App/>, target);

export default App;
