import React, {FC, SyntheticEvent, useEffect, useState} from 'react';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';

export interface YearSliderProps {
  years: Array<number>,
  selectedYear: number,
  onYearChange: (year: number | number[]) => void,
}

const YearSlider: FC<YearSliderProps> = ({years, selectedYear, onYearChange}) => {
  const [internalYear, setInternalYear] = useState<number | number[]>(selectedYear);
  useEffect(() => setInternalYear(selectedYear), [selectedYear]);

  const handleChange = (event: Event, value: number | number[]) => setInternalYear(value);
  const handleChangeCommitted = (event: Event | SyntheticEvent, value: number | number[]) => onYearChange(value);

  const marks = years?.map(year => ({value: year, label: year}));

  return <Box sx={{width: '100%'}}>
    {years?.length &&
      <Slider
        value={internalYear}
        min={years[0]}
        max={years[years.length - 1]}
        marks={marks}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        track={false}
        valueLabelDisplay="auto"
        sx={{width: '100%'}}
      />
    }
  </Box>;
};

export default YearSlider;
