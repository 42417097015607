import React from 'react';

import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ErrorIcon from '@mui/icons-material/Dangerous';
import Skeleton from '@mui/material/Skeleton';

const FetchStatusWrapper = ({isWaiting, isEmpty, error, children, height, width, errorText, emptyText}) => {
  if (isWaiting) {
    return <Skeleton variant='rectangular' animation='wave' height={height} width={width}/>;
  }

  if (error) {
    return <Box display="flex" height={height} width={width} sx={{color: 'error.main'}}>
      <Box m="auto">
        <Grid container direction="row" alignItems="center">
          <ErrorIcon/> {errorText}
        </Grid>
      </Box>
    </Box>;
  }

  if (isEmpty) {
    return <Box display="flex" height={height} width={width}>
      <Box m="auto">{emptyText}</Box>
    </Box>;
  }

  return children;
};

FetchStatusWrapper.propTypes = {
  isWaiting: PropTypes.bool,
  isEmpty: PropTypes.bool,
  error: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  errorText: PropTypes.string,
  emptyText: PropTypes.string
};

FetchStatusWrapper.defaultProps = {
  isWaiting: false,
  isEmpty: false,
  error: undefined,
  children: null,
  height: 32,
  width: '100%',
  errorText: 'Error',
  emptyText: '(empty)'
};

export default FetchStatusWrapper;
